// Chakra imports
import {
  Alert,
  AlertIcon,
  Box, Button, Flex, FormControl,
  FormLabel,
  Heading,
  Input,
  Link, Text,
  useColorModeValue
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signinImg2.jpg";
import { useFormik } from 'formik';
import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { LOGIN } from 'store/users';
import * as Yup from 'yup';
import AuthApi from '../../AuthApi';

function SignIn(props) {
  // Chakra color mode
  const dispatch = useDispatch()
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const Auth = useContext(AuthApi)
  const [isError,setIsError] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')

  const validationSchema = Yup.object({
    username: Yup.string().required('บังคับกรอก'),
    password: Yup.string().required('บังคับกรอก'),
  })

  const history = useHistory();
  const routeSignup = () =>{ 
    let path = `/signup`; 
    history.push(path);
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const formik = useFormik({
    initialValues: {
      role:'User'
    },
    onSubmit: (values, { setSubmitting }) => {
        dispatch(LOGIN(values.username, values.password)).then(data => {
          setSubmitting(false)
          Auth.setAuth(true)
          Cookies.set('role', data.role)
          Cookies.set('token', data.token)
          Cookies.set('user_id', data.id)
          // props.history.location.setRolePage(data.role)
          refreshPage()
          history.push('/admin/users')
        })
        .catch(e => {
          let errorMessageEN = e.response.data.message
          if (errorMessageEN == 'This user is pending approval.') {
            setErrorMessage('ผู้ใช้งานยังไม่ได้รับการอนุมัติ')
          } else if (errorMessageEN == 'password is invalid.') {
            setErrorMessage('รหัสผ่านไม่ถูกต้อง')
          } else if (errorMessageEN == 'This username is not registered yet.') {
            setErrorMessage('ยังไม่มีข้อมูลในระบบ')
          }
          setIsError(true)
          setSubmitting(true)
        })
    },
    validationSchema
  })

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "60%", lg: "60%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='2rem'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='3rem' mb='10px'>
              ยินดีต้อนรับ
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='1rem'>
              KID D EXAMINATION
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <FormLabel ms='4px' fontWeight='normal'>
                  ชื่อผู้ใช้งาน
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='24px'
                  type='text'
                  size='lg'
                  name="username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username || ''}
                />
                <FormLabel ms='4px' fontWeight='normal'>
                  รหัสผ่าน
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  type='password'
                  size='lg'
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password || ''}
                />
                { isError ? <Alert status='error' mb='20px'>
                  <AlertIcon />
                  {errorMessage}
                </Alert> : ''}
                  <Button
                    fontSize='1.2rem'
                    type='submit'
                    bg='teal.300'
                    w='100%'
                    h='45'
                    mb='20px'
                    color='white'
                    mt='20px'
                    _hover={{
                      bg: "teal.200",
                    }}
                    _active={{
                      bg: "teal.400",
                    }}>
                    เข้าสู่ระบบ
                  </Button>
              </FormControl>
            </form>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                ยังไม่มีบัญชีผู้ใช้งาน?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold' onClick={routeSignup}>
                  สมัครสมาชิก
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            bgImage={signInImage}
            w='100%'
            h={{ base: "65%", md: "80%", xl: "100%" }}
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
