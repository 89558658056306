import { Button, Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

function TablesHisoryExamtRow(props) {
  const { name, group, date, score, quantity, percent, role, exam_id, wrongAnswers, history_duration } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const textColor2 = useColorModeValue("white", "gray.700");
  const history = useHistory();
  const routeWrongExam = () =>{
    let path = `/exam/wrongexam/`;
    history.push({
      pathname: path,
      state: {
        examname: name,
        score: score,
        questionlength: quantity,
        exam_id: exam_id,
        wrongAnswers: wrongAnswers,
        history_duration, history_duration
      }
    });
  }

  return (
    <Tr >
      <Td minWidth={{ sm: "180px" }} pl="0px" py="10px">
        <Flex alignItems="center" py=".5rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td py="10px">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {group}
        </Text>
      </Td>
      <Td py="10px">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {date}
        </Text>
      </Td>
      <Td py="10px">
        {role == 'admin' ?
          (<Button colorScheme='teal' borderRadius='5px' onClick={routeWrongExam}>
              <Text fontSize="md" color={textColor2} fontWeight="bold">
                {score} / {quantity}
              </Text>
            </Button>) : (
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {score} / {quantity}
            </Text>
          )
        }
      </Td>
      <Td py="10px">
        <Text fontSize="md" color={textColor} fontWeight="bold">
          {percent}%
        </Text>
      </Td>

    </Tr>
  );
}

export default TablesHisoryExamtRow;
