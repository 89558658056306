import {
  Box, Button,
  Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Td,
  Text,
  Tr,
  useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DELETE_QUESTION } from "store/questions";

function TablesTableRow(props) {
  const { index, question, questionId } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  const { id } = useParams()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const routeEdit = () =>{
    let path = `/admin/exams/${id}/question/${questionId}/edit`;
    history.push(path);
  }

  if (questionId == null) {
    return  <Tr>
              <Td>
                <Flex direction="column">
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    ยังไม่มีข้อมูล
                  </Text>
                </Flex>
              </Td>
              <Td></Td>
              <Td></Td>
            </Tr>
  }

  const confirmDelete = () => {
    onOpen()
  }

  const deleteQuestion = () => {
    dispatch(DELETE_QUESTION(questionId))
    .then(data => {
      onClose()
      history.push(`/admin/exams/${id}/question/`)
    })
    .catch(e => {
      onClose()
    })
  }

  return (
    <Tr>
      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {index}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              minWidth="100%"
            >
              {question}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeEdit}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            แก้ไข
          </Text>
        </Button>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={confirmDelete}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            ลบโจทย์
          </Text>
        </Button>
      </Td>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box w="100%" align='center'>
              ต้องการลบโจทย์ {question}
            </Box>
          </ModalHeader>
          <ModalFooter>
            <Box w="100%" align='center'>
              <Button colorScheme="red" variant='outline' onClick={deleteQuestion}>
                ลบโจทย์
              </Button>
              <Button ml="15px" colorScheme="teal" onClick={onClose}>
                ยกเลิก
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tr>

  );
}

export default TablesTableRow;
