export const ReducerExams = {
    SET_EXAMS: 'SET_EXAMS',
    SET_EXAM: 'SET_EXAM',
}

export const ReducerCourses = {
    SET_COURSES: 'SET_COURSES',
    SET_COURSE: 'SET_COURSE',
    SET_COURSES_USERS: 'SET_COURSES_USERS'
}

export const ReducerQuestions = {
    SET_QUESTIONS: 'SET_QUESTIONS',
    SET_QUESTION: 'SET_QUESTION',
}

export const ReducerUsers = {
    SET_PROFILE: 'SET_PROFILE',
    SET_USERS: 'SET_USERS',
    SET_USER: 'SET_USER'
}