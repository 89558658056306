import { Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GET_USER_BY_ID } from 'store/users'
import HistoryExam from 'views/User/Profile/components/HistoryExam'
import ProfileInformation from 'views/User/Profile/components/ProfileInformation'

function StatUser() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white')
  const { id } = useParams()

  const initFetch = useCallback(() => {
    dispatch(GET_USER_BY_ID(id))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  const data = useSelector((state) => state.users.user)

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px'>
        <GridItem colSpan={1}>
          <ProfileInformation
            title={"ข้อมูลนักเรียน"}
            fullname={`${data?.firstname} ${data?.lastname}`}
            classroom={data?.class}
            school={data?.school}
            mobile={data?.mobile}
            email={data?.email}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <HistoryExam
            title={"ประวัติการทำข้อสอบ"}
            captions={["ชื่อชุดข้อสอบ", "คอร์สเรียน", "วันที่ทำข้อสอบ", "คะแนน", "เปอร์เซ็นต์"]}
            data={data?.history}
            maxHeight='800px'
            role='admin'
          />
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default StatUser
