import { Button, Flex, FormControl, FormLabel, Text, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { GET_COURSES, GET_COURSE_BY_USER_ID } from 'store/courses'
import { GET_USER_BY_ID, UPDATE_COURSE_BY_USER } from 'store/users'
import * as Yup from 'yup'

function EditUser() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white')
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');
  const { id } = useParams()
  const [userName, setUserName] = useState('')

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: '#E2E8F0',
      minHeight: '3rem',
      marginBottom: '24px'
    }),
    option: (styles, state) => ({
      ...styles,      
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }), 
  }

  const listCourse = useSelector((state) => state.courses.lists)
  const optionsCourse = Object.values(listCourse).map((item)=> ({
    value: item.id,
    label: item.name
  }))

  const validationSchema = Yup.object({
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values, { setSubmitting }) => {
      let mapCourseID = values.course_id.map((i) => i.value)
      dispatch(UPDATE_COURSE_BY_USER(
        {
          user_id: parseInt(id),
          course_id : mapCourseID
        }
      ))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/users')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  const initFetch = useCallback(() => {
    dispatch(GET_COURSES(''))
    dispatch(GET_USER_BY_ID(id)).then((data) => {
      setUserName(`${data.firstname} ${data.lastname}`)
    })
    dispatch(GET_COURSE_BY_USER_ID(id)).then((data) => {
      const filteredCourseId = [];
      data.forEach(filterValue => {
        filteredCourseId.push(optionsCourse.filter(val => val.value === parseInt(filterValue.course_id))[0]);
      });
      formik.setFieldValue('course_id', filteredCourseId)
    })
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <form initialvalues={formik.initialValues} onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader>
            <Flex justify="space-between" align="center" minHeight="60px" w="100%">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
              แก้ไขคอร์สเรียนของ {userName}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody p="20px">
            <FormControl>
              <FormLabel ms='4px' fontWeight='semibold'>
                คอร์สเรียน
              </FormLabel>
              <Select 
                isMulti
                id='course_id' 
                name='course_id'
                placeholder='เลือกคอร์สเรียน'
                options={optionsCourse}
                styles={customStyles}
                value={formik.values.course_id || ''}
                onChange={(selectedOption) => {
                  let e = { target: { name: 'course_id', value: selectedOption } };
                  formik.handleChange(e);
              }}
                onBlur={formik.handleBlur}  />
              <Button
                type="submit"
                bg="teal.300"
                fontSize="1rem"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                ตกลง
              </Button>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  )
}

export default EditUser
