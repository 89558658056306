// Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
import BgSignUp from "assets/img/BgSignUp.png";
import AuthApi from "AuthApi";
import Footer from "components/Footer/Footer.js";
import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
// Custom Chakra theme
import theme from "theme/theme.js";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const Auth = useContext(AuthApi)
  // functions for changing the states from components

  const ProtectedRoute = ({auth, component:Component, ...rest}) => {
    return(
      <Route
      {...rest}
      render = {() => auth ? (
        <Component />
      ): <Redirect to="/login"/>
    }
      />
    )
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/exam") {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            auth={Auth.auth}
          />
        );
      } else {
        return null;
      }
    });
  };

  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <MainPanel
        w={{
          base: "100%"
        }}
        h={{
            base: "100%"
          }}
        bgImage={BgSignUp}
        bgSize='cover'
        bgRepeat='no-repeat'
        position='fixed'
      >
        <PanelContent>
        <PanelContainer>
            <Switch>
            {getRoutes(routes)}
            </Switch>
        </PanelContainer>
        </PanelContent>
        <Footer />
      </MainPanel>
    </ChakraProvider>
  );
}
