import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';

function TimeCountdown({ expiryTimestamp, checkCurrentQuestion, handleAnswerButtonClick, question_id }) {
    const [isExpire, setIsExpire] = useState(false);
    const {
        seconds,
        minutes,
        restart,
        start,
    } = useTimer({ expiryTimestamp, onExpire: () => {
      setIsExpire(true)
      handleAnswerButtonClick(false, question_id)}});
    const bgColor = useColorModeValue("white", "gray.700");

    useEffect(() => {
        restart(expiryTimestamp);
    }, [checkCurrentQuestion]);

    useEffect(()=>{
      start()
    },[])

    useEffect(()=>{
      if(isExpire) {
        setTimeout(function() { //Start the timer
          restart(expiryTimestamp);
        }.bind(this), 10)
      }
      setIsExpire(false)
    },[isExpire])
    

    return (
        <Flex alignItems='center' justifyContent='center' mt='1rem'>
            <Flex
                direction='column'
                w={{ base: "200px", md: "400px" }}
                background='transparent'
                borderRadius='15px'
                p={{ base: "0.5rem", md: "1rem" }}
                bg={bgColor}
                boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                <Text fontSize={{ base: "1.2rem", md: "1.5rem" }} fontWeight='bold'>เหลือเวลาอีก <span>{minutes}</span>:<span>{seconds < 10 ? `0${seconds}` : seconds}</span></Text>
            </Flex>
        </Flex>
    );
}

export default TimeCountdown;