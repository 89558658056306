import { Box, Button, Flex, FormControl, FormLabel, Input, Text, useColorModeValue, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { GET_USER_BY_ID, UPDATE_USER } from 'store/users'
import { CLASS, STATUS } from 'variables/enumObject'
import * as Yup from 'yup'

function EditUser() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white')
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');
  const { id } = useParams()

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: '#E2E8F0',
      height: '3rem',
      marginBottom: '24px'
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }),
  }

  const validationSchema = Yup.object({
    firstname: Yup.string().required('บังคับกรอก'),
    lastname: Yup.string().required('บังคับกรอก'),
    username: Yup.string().required('บังคับกรอก'),
    password: Yup.string().min(2, 'สั้นไป!').max(20, 'ยาวไป!').required('บังคับกรอก')
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values, { setSubmitting }) => {
      dispatch(UPDATE_USER(
        id,
        {
          status: values.status.value,
          firstname: values.firstname,
          lastname: values.lastname,
          class: values.class?.label,
          school: values.school,
          email: values.email,
          mobile: values.mobile,
          print_status: {
            questions: values.print,
            answers: values.print
          }
        }
      ))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/users')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  const initFetch = useCallback(() => {
    dispatch(GET_USER_BY_ID(id)).then((data) => {
        formik.setFieldValue('firstname', data.firstname)
        formik.setFieldValue('lastname', data.lastname)
        formik.setFieldValue('username', data.username)
        formik.setFieldValue('password', data.password)
        formik.setFieldValue('class', CLASS.filter(x => x.label === data.class)[0])
        formik.setFieldValue('school', data.school)
        formik.setFieldValue('email', data.email)
        formik.setFieldValue('mobile', data.mobile)
        formik.setFieldValue('status', STATUS.filter(x => x.value === data.status)[0])
        let print_status = 'false'
        if (data.print_status !== null && data.print_status !== '' && data.print_status !== undefined) {
          print_status = (data.print_status?.questions === 'true') ? 'true' : 'false'
        }
        formik.setFieldValue('print', print_status)
    })
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <form initialvalues={formik.initialValues} onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader>
            <Flex justify="space-between" align="center" minHeight="60px" w="100%">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
              แก้ไขผู้ใช้งาน
              </Text>
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                <Select
                  options={STATUS}
                  id="status"
                  name="status"
                  placeholder="เลือกชั้นเรียน"
                  styles={customStyles}
                  onChange={selectedOption =>
                    formik.setFieldValue("status", selectedOption)
                  }
                  value={formik.values.status}
                  onBlur={formik.handleBlur} />
              </Text>
            </Flex>
          </CardHeader>
          <CardBody p="20px">
            <FormControl>
              <Flex gap="5">
                <Box flex="1">
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal' >
                    ชื่อจริง
                    {formik.touched.firstname && formik.errors.firstname && (
                      <Text color='tomato'>{formik.errors.firstname}</Text>
                    )}
                  </FormLabel>
                  <Input
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    name="firstname"
                    placeholder='Your fisrtname'
                    mb='24px'
                    size='lg'
                    isInvalid={(formik.touched.firstname && formik.errors.firstname) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname || ''}
                  />
                </Box>
                <Box flex="1">
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    นามสกุล
                    {formik.touched.lastname && formik.errors.lastname && (
                      <Text color='tomato'>{formik.errors.lastname}</Text>
                    )}
                  </FormLabel>
                  <Input
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    name="lastname"
                    placeholder='Your lastname'
                    mb='24px'
                    size='lg'
                    isInvalid={(formik.touched.lastname && formik.errors.lastname) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname || ''}
                  />
                </Box>
              </Flex>
              <Flex gap="5">
                <Box flex="1">
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    ชื่อผู้ใช้งาน
                    {formik.touched.username && formik.errors.username && (
                      <Text color='tomato'>{formik.errors.username}</Text>
                    )}
                  </FormLabel>
                  <Input
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    name="username"
                    mb='24px'
                    size='lg'
                    isInvalid={(formik.touched.username && formik.errors.username) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                    value={formik.values.username || ''}
                  />
                </Box>
                <Box flex="1">
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    รหัสผ่าน
                    {formik.touched.password && formik.errors.password && (
                      <Text color='tomato'>{formik.errors.password}</Text>
                    )}
                  </FormLabel>
                  <Input
                    fontSize='sm'
                    ms='4px'
                    type="text"
                    name="password"
                    borderRadius='15px'
                    mb='24px'
                    size='lg'
                    isInvalid={(formik.touched.password && formik.errors.password) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                    value={formik.values.password || ''}
                  />
                </Box>
              </Flex>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                ชั้นเรียน
              </FormLabel>
              <Select
                options={CLASS}
                id="class"
                name="class"
                placeholder="เลือกชั้นเรียน"
                styles={customStyles}
                onChange={selectedOption =>
                  formik.setFieldValue("class", selectedOption)
                }
                value={formik.values.class}
                onBlur={formik.handleBlur} />
                {formik.errors.class && formik.touched.class && formik.errors.class}
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal' >
                โรงเรียน
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                name="school"
                mb='24px'
                size='lg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.school || ''}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                อีเมล
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                mb='24px'
                type="email"
                name="email"
                size='lg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email || ''}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal' >
                เบอร์โทรศัพท์ (ผู้ปกครอง)
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                name="mobile"
                mb='24px'
                size='lg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile || ''}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal' >
                สถานะปุ่มพิมพ์ข้อสอบ
              </FormLabel>
              <RadioGroup mb='24px' onChange={value => formik.setFieldValue("print", value)} value={formik.values.print}>
                <Stack spacing={5} direction='row'>
                  <Radio colorScheme='green' value='true'>
                    เปิดการใช้งาน
                  </Radio>
                  <Radio colorScheme='red' value='false'>
                    ปิดการใช้งาน
                  </Radio>
                </Stack>
              </RadioGroup>
              <Button
                type="submit"
                bg="teal.300"
                fontSize="1rem"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                ตกลง
              </Button>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  )
}

export default EditUser
