import axios from 'axios'
import Cookies from 'js-cookie'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get('token')}`
  return config
})

export default {
  async login (body) {
    const { data } = await http.post('/api/login', body)
    return data
  },
  async register (body) {
    const { data } = await http.post('/api/register', body)
    return data
  },
  async getUsers (params) {
    const { data } = await http.get('/api/user' + params)
    return data
  },
  async getUserById (id) {
    const { data } = await http.get('/api/user/' + id)
    return data
  },
  async updateUsers (id, body) {
    const { data } = await http.put('/api/user/' + id, body)
    return data
  },
  async updateUsersCourse (body) {
    const { data } = await http.post('/api/user/course', body)
    return data
  },
  async sendHistory (body) {
    const { data } = await http.post('/api/user/history', body)
    return data
  },
  async getCourses (params) {
    const { data } = await http.get('/api/course' + params)
    return data
  },
  async getCourseById (id) {
    const { data } = await http.get('/api/course/' + id)
    return data
  },
  async getCourseByUserId (id) {
    const { data } = await http.get('/api/course?user_id=' + id)
    return data
  },
  async createCourse (body) {
    const { data } = await http.post('/api/course', body)
    return data
  },
  async updateCourses (id, body) {
    const { data } = await http.put('/api/course/' + id, body)
    return data
  },
  async getExams (params) {
    const { data } = await http.get('/api/exam' + params)
    return data
  },
  async getExamById (id) {
    const { data } = await http.get('/api/exam/' + id)
    return data
  },
  async getExamByCourse (id) {
    const { data } = await http.get('/api/exam?course=' + id)
    return data
  },
  async createExam (body) {
    const { data } = await http.post('/api/exam', body)
    return data
  },
  async updateExam (id, body) {
    const { data } = await http.put('/api/exam/' + id, body)
    return data
  },
  async deleteExam (id) {
    const  { data } = await http.delete('/api/exam/' + id)
    return data
  },
  async getQuestions () {
    const { data } = await http.get('/api/question')
    return data
  },
  async getQuestionById (id) {
    const { data } = await http.get('/api/question/' + id)
    return data
  },
  async getQuestionByExam (id) {
    const { data } = await http.get('/api/question?exam=' + id)
    return data
  },
  async createQuestion (body) {
    const { data } = await http.post('/api/question', body)
    return data
  },
  async updateQuestion (id, body) {
    const { data } = await http.put('/api/question/' + id, body)
    return data
  },
  async deleteQuestion (id) {
    const  { data } = await http.delete('/api/question/' + id)
    return data
  },
  async addQuestionJsonExcel (body) {
    const { data } = await  http.post('/api/excel/json', body)
    return data
  }
}
