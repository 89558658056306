// Chakra imports
import { Flex } from "@chakra-ui/react";
import Cookies from "js-cookie";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GET_EXAM_BY_COURSE } from "store/exams";
import { GET_USER_BY_ID } from "../../../store/users";
import Authors from "./components/Authors";

function Tables() {
  const location = useLocation();
  const dispatch = useDispatch()
  const { id } = useParams()
  const initFetch = useCallback(() => {
    dispatch(GET_EXAM_BY_COURSE(id))
    dispatch(GET_USER_BY_ID(Cookies.get('user_id')))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  const courseinfo = useSelector((state) => state.exams.exam)
  const user = useSelector((state) => state.users.user)
  let captions = ["ชื่อชุดข้อสอบ", "จำนวนข้อ", ""]

  if (user.print_status !== null && user.print_status?.questions === 'true') {
    captions = ["ชื่อชุดข้อสอบ", "จำนวนข้อ", "", "พิมพ์ข้อสอบ"]
  }

  if (location.pathname.split('/')[2] == 'allcourse') {
    return (
      <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
        <Authors
          title={"ข้อมูลชุดข้อสอบ"}
          captions={["ชื่อชุดข้อสอบ", "จำนวนข้อ"]}
          page='allcourse'
          data={courseinfo}
        />
      </Flex>
    );
  } else if (location.pathname.split('/')[2] == 'myfreecourse') {
    return (
      <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
        <Authors
          title={"ข้อมูลชุดข้อสอบ"}
          captions={captions}
          page='myfreecourse'
          data={courseinfo}
          print={(user.print_status !== null && user.print_status?.questions === 'true') ? 'true' : 'false'}
        />
      </Flex>
    );
  }

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Authors
        title={"ข้อมูลชุดข้อสอบ"}
        captions={captions}
        page='mycourse'
        data={courseinfo}
        print={(user.print_status !== null && user.print_status?.questions === 'true') ? 'true' : 'false'}
      />
    </Flex>
  );
}

export default Tables;
