import { createSlice } from '@reduxjs/toolkit';
import api from './api';
import { ReducerUsers } from './types';

export const LOGIN = (username, password) => async (dispatch) => {
  const res = await api.login({ username: username, password: password })
  dispatch({ type: ReducerUsers.SET_PROFILE, data: res.data })
  return res.data
}

export const GET_USERS = (params) => async (dispatch) => {
  const res = await api.getUsers(params)
  dispatch({ type: ReducerUsers.SET_USERS, data: res.data , meta: res})
}

export const GET_USER_BY_ID = (id) => async (dispatch) => {
  const res = await api.getUserById(id)
  dispatch({ type: ReducerUsers.SET_USER, data: res.data })
  return res.data
}

export const REGISTER = (body) => async (dispatch) => {
  const res = await api.register(body)
  return res.data
}

export const UPDATE_USER = (id, body) => async (dispatch) => {
  const res = await api.updateUsers(id, body)
  return res.data
}

export const UPDATE_COURSE_BY_USER = (body) => async (dispatch) => {
  const res = await api.updateUsersCourse(body)
  return res.data
}

export const SEND_HISTORY = (body) => async (dispatch) => {
  const res = await api.sendHistory(body)
  return res.data
}

const UsersReducer = createSlice({
  name: 'users',
  initialState: {
    lists: [],
    profile: {},
  },
  extraReducers: {
    [ReducerUsers.SET_PROFILE]: (state, action) => {
      state.profile = action.data
    },
    [ReducerUsers.SET_USERS]: (state, action) => {
      state.lists = action.data
      state.meta = action.meta
    },
    [ReducerUsers.SET_USER]: (state, action) => {
      state.user = action.data
    }
  },
})

const { reducer } = UsersReducer
export default reducer
