import {
  Badge,
  Box,
  Button,
  Flex,
  Link,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CourseDetailTableRow from "components/Tables/CourseDetailTableRow";
import React from "react";
import { FaFacebookMessenger } from "react-icons/fa";

const Authors = ({ title, captions, data, page, print }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  const examlists = data.exams
  let isMyCourse = (page == 'mycourse' || page == 'myfreecourse') ? true : false

  const compareNames = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
  
    return nameA.localeCompare(nameB, 'en', { numeric: true });
  };
  
  // Sort examlists array by name
  const sortedExamlists = examlists ? [...examlists].sort(compareNames) : [];

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title} <Badge borderRadius='10px' variant='outline' fontSize='1rem' p='0.4rem'>{data.course_name}</Badge>
          </Text>
          <Spacer />
          {!isMyCourse && <Link target="_blank" href={`https://m.me/theKiddschool?ref=${data.course_name}`}>
            <Button size='sm' bgColor='#4267B2' color='white'>
              <FaFacebookMessenger/><Text ml='5px'>ติดต่อเรา</Text>
            </Button>
          </Link>}
          <Box fontSize='lg' color={textColor} fontWeight='bold'>
            <Badge mx='1rem' colorScheme='green'>{data.subject}</Badge>
            <Badge mx='1rem' colorScheme='green'>{data.class}</Badge>
          </Box>
        </Flex>
      </CardHeader>
      {data.course_desc != '' ? <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text whiteSpace='pre-line' color={textColor}>
            รายละเอียดคอร์ส : {data.course_desc}
          </Text>
        </Flex>
      </CardHeader> : null}
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th
                    color='gray.400'
                    key={idx} ps={idx === 0 ? "0px" : null}
                    textAlign={(caption === 'พิมพ์ข้อสอบ') ? 'center' : 'left'}
                  >
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {sortedExamlists ? sortedExamlists.map((row) => {
              return (
                <CourseDetailTableRow
                  key={row.id}
                  name={row.name}
                  quantity={row.quantity}
                  id={row.id}
                  course_id={data.course_id}
                  page={page}
                  print={print}
                />
              );
            }) : null}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Authors;
