// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Input, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { GET_COURSES } from 'store/courses';
import { CREATE_EXAM } from 'store/exams';
import { DURATION } from 'variables/enumObject';
import * as Yup from 'yup';

function AddExam() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: '#E2E8F0',
      minHeight: '3rem',
      marginBottom: '24px'
    }),
    option: (styles, state) => ({
      ...styles,      
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }), 
  }

  const initFetch = useCallback(() => {
    dispatch(GET_COURSES(''))
  }, [dispatch])
  useEffect(() => {
    initFetch()
  }, [initFetch])

  const listCourse = useSelector((state) => state.courses.lists)

  const optionsCourse = Object.values(listCourse).map((item)=> ({
    value: item.id,
    label: item.name
  }))

  const validationSchema = Yup.object({
    name: Yup.string().required('บังคับกรอก'),
    course_id: Yup.array().required('บังคับกรอก')
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      status: false,
      duration: '120'
    },
    onSubmit: (values, { setSubmitting }) => {
      let mapCourseID = values.course_id.map((i) => i.value)
      values = {
        ...values,
        course_id : mapCourseID.toString()
      }
      dispatch(CREATE_EXAM(values))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/exams')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader>
            <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                เพิ่มข้อสอบ
              </Text>
            </Flex>
          </CardHeader>
          <CardBody p='20px'>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                ชื่อข้อสอบ
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                mb='24px'
                size='lg'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ''}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                คอร์สเรียน
              </FormLabel>
              <Select 
                isMulti
                id='course_id' 
                name='course_id'
                placeholder='เลือกคอร์สเรียน'
                options={optionsCourse}
                styles={customStyles}
                onChange={(selectedOption) => {
                  let e = { target: { name: 'course_id', value: selectedOption } };
                  formik.handleChange(e);
              }}
                onBlur={formik.handleBlur}  />
              <Flex gap='5'>
                <Box flex='1'>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    ระยะเวลาแต่ละข้อ
                  </FormLabel>
                  <Select 
                    options={DURATION}
                    id='duration'
                    name='duration' 
                    placeholder='เลือกเวลา' 
                    styles={customStyles}
                    onChange={selectedOption =>
                      formik.setFieldValue('duration', selectedOption.value)
                    }
                    onBlur={formik.handleBlur} />
                </Box>
              </Flex>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                รายละเอียด
              </FormLabel>
              <Textarea
                id='description'
                name='description'
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='textarea'
                mb='24px'
                size='lg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description || ''}
              />
              <Button
                type='submit'
                bg='teal.300'
                fontSize='20px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                disabled={Object.keys(formik.errors).length != 0 ? true : false}
                _hover={{
                  bg: 'teal.200',
                }}
                _active={{
                  bg: 'teal.400',
                }}>
                สร้างข้อสอบ
              </Button>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default AddExam;
