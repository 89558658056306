export const SUBJECT = [
  {
    value: 'sci',
    label: 'วิทยาศาสตร์'
  },
  {
    value: 'math',
    label: 'คณิตศาสตร์'
  },
  {
    value: 'english',
    label: 'ภาษาอังกฤษ'
  },
  {
    value: 'thai',
    label: 'ภาษาไทย'
  },
  {
    value: 'social',
    label: 'สังคมศึกษา'
  }
]
export const CLASS = [
  {
    value: 'p1',
    label: 'ประถมศึกษาปีที่ 1'
  },
  {
    value: 'p2',
    label: 'ประถมศึกษาปีที่ 2'
  },
  {
    value: 'p3',
    label: 'ประถมศึกษาปีที่ 3'
  },
  {
    value: 'p4',
    label: 'ประถมศึกษาปีที่ 4'
  },
  {
    value: 'p5',
    label: 'ประถมศึกษาปีที่ 5'
  },
  {
    value: 'p6',
    label: 'ประถมศึกษาปีที่ 6'
  },
  {
    value: 'm1',
    label: 'มัธยมศึกษาปีที่ 1'
  },
  {
    value: 'm2',
    label: 'มัธยมศึกษาปีที่ 2'
  },
  {
    value: 'm3',
    label: 'มัธยมศึกษาปีที่ 3'
  },
]
export const DURATION = [
  {
    value: '60',
    label: '1 นาที'
  },
  {
    value: '120',
    label: '2 นาที'
  },
  {
    value: '180',
    label: '3 นาที'
  },
  {
    value: '240',
    label: '4 นาที'
  },
  {
    value: '300',
    label: '5 นาที'
  }
]
export const STATUS = [
  {
    value: 'Pending Approval',
    label: 'รออนุมัติ'
  },
  {
    value: 'Active',
    label: 'เปิดใช้งาน'
  },
  {
    value: 'Inactive',
    label: 'ปิดใช้งาน'
  }
]