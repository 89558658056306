// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import Exams from "./components/Exams";


function MyFreeCourse() {

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Exams title={"คอร์สเรียนฟรีของฉัน"} description={""} />
    </Flex>
  );
}

export default MyFreeCourse;
