import { createSlice } from '@reduxjs/toolkit';
import api from './api';
import { ReducerCourses } from './types';

export const GET_COURSES = (params) => async (dispatch) => {
  const res = await api.getCourses(params)
  dispatch({ type: ReducerCourses.SET_COURSES, data: res.data, meta: res })
}

export const GET_COURSE_BY_ID = (id) => async (dispatch) => {
  const res = await api.getCourseById(id)
  dispatch({ type: ReducerCourses.SET_COURSE, data: res.data })
  return res.data
}

export const GET_COURSE_BY_USER_ID = (id) => async (dispatch) => {
  const res = await api.getCourseByUserId(id)
  dispatch({ type: ReducerCourses.SET_COURSES_USERS, data: res.data })
  return res.data
}

export const CREATE_COURSE = (body) => async (dispatch) => {
  const res = await api.createCourse(body)
  return res.data
}

export const UPDATE_COURSE = (id, body) => async (dispatch) => {
  const res = await api.updateCourses(id, body)
  return res.data
}

const coursesReducer = createSlice({
  name: 'courses',
  initialState: {
    lists: [],
    listsUser: [],
    course: {}
  },
  extraReducers: {
    [ReducerCourses.SET_COURSES]: (state, action) => {
      state.lists = action.data
      state.meta = action.meta
    },
    [ReducerCourses.SET_COURSES_USERS]: (state, action) => {
      state.listsUser = action.data
    },
    [ReducerCourses.SET_COURSE]: (state, action) => {
      state.course = action.data
    }
  },
})

const { reducer } = coursesReducer
export default reducer
