import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react"
import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { GET_QUESTION_BY_EXAM } from 'store/questions'
import { pdfQuestions, pdfAnswer } from "../../utils/pdfmake"

function TablesTableRow(props) {
  const { id, name, quantity, filename } = props
  const textColor = useColorModeValue("gray.700", "white")
  const bgStatus = useColorModeValue("gray.400", "#1a202c")
  const colorStatus = useColorModeValue("white", "gray.400")
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [loadingAnswers, setLoadingAnswers] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const routeEdit = () =>{
    let path = `/admin/exams/${id}/edit/`
    history.push(path)
  }
  const routeQuestion = () =>{
    let path = `/admin/exams/${id}/question/`
    history.push(path)
  }

  const print = async () => {
    setLoadingQuestions(true)
    let res = await dispatch(GET_QUESTION_BY_EXAM(id))
    await pdfQuestions(res)
    setLoadingQuestions(false)
  }

  const printAnswer = async () => {
    setLoadingAnswers(true)
    const res = await dispatch(GET_QUESTION_BY_EXAM(id))
    await pdfAnswer(res)
    setLoadingAnswers(false)
  }

  return (
    <Tr>
      <Td minWidth={{ sm: "300px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td maxWidth='300px'>
        <Text fontSize="md" whiteSpace="pre-wrap" color={textColor} pb=".5rem">
          {filename?.map((a) => {return a + "\n"})}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem">
          {quantity}
        </Text>
      </Td>
      <Td textAlign='center'>
        <Button
          colorScheme='gray'
          variant='ghost'
          onClick={print} mr='5px'
          isLoading={loadingQuestions}
          loadingText='คำถาม'
        >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            คำถาม
          </Text>
        </Button>
        <Button
          colorScheme='gray'
          variant='ghost'
          onClick={printAnswer}
          isLoading={loadingAnswers}
          loadingText='เฉลย'
        >
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            เฉลย
          </Text>
        </Button>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeEdit}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            แก้ไข
          </Text>
        </Button>
      </Td>
      <Td>
          <Button p="0px" bg="transparent" variant="no-hover" onClick={routeQuestion}>
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              คำถาม
            </Text>
          </Button>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
