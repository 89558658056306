// chakra imports
// core components
import {
  Avatar, Box, Button, chakra, ChakraProvider, Container, Flex, Heading, HStack, Icon, IconButton, Image, Link, SimpleGrid, Skeleton, Stack,
  Text, useColorModeValue, useDisclosure, VStack
} from '@chakra-ui/react';
import facebookImage from "assets/img/facebook.png";
import facebookPageImage from "assets/img/facebookPage.png";
import homeImage from "assets/img/home.jpg";
import lineImage from "assets/img/line.png";
import logoImage from "assets/img/logo.png";
import regisImage from "assets/img/regis.jpg";
import youtubeImage from "assets/img/youtubeChanel.png";
import React, { Fragment } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImQuotesLeft } from 'react-icons/im';
import theme from "theme/theme.js";


const testimonials = [
  {
    name: 'ศนันธร พิชัย',
    position: 'Teacher',
    company: 'Kid D School',
    image:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb',
    content:
      'เป็นเกียรติอย่างยิ่งที่ได้มีส่วนร่วมในการส่งเสริมการศึกษาและเตรียมความพร้อมในการสอบของนักเรียนทุกคน ทางโรงเรียนกวดวิชามีความตั้งใจและความพยายามที่จะให้บริการที่มีคุณภาพสูงสุดแก่นักเรียนทุกคน โดยการให้บริการที่มีความเหมาะสมและครบวงจร เรามีคณาจารย์ที่มีความเชี่ยวชาญในการสอนและการเตรียมความพร้อมในการสอบ และมีสถานที่เรียนรู้ที่เหมาะสมและครบครันที่จะช่วยให้นักเรียนได้รับการเตรียมความพร้อมในการสอบอย่างเต็มที่ ดิฉันอยากเห็นผลการเรียนรู้ที่ดีและความสำเร็จในการสอบของนักเรียนทุกคน ดังนั้นโรงเรียนกวดวิชาของเราจึงให้ความสำคัญกับการให้บริการที่มีคุณภาพและการสนับสนุนในการเตรียมความพร้อมในการสอบของนักเรียนอย่างเต็มที่ ขอบคุณที่ให้ความสนใจและมีส่วนร่วมกับโรงเรียนกวดวิชาของเรา'
  }
];

const featuresDetail = [
  {
    heading: 'สอนเตรียมสอบ',
    content: 'ช่วยเตรียมความพร้อมของนักเรียนในการสอบเข้าสู่สถาบันการศึกษาที่ต้องการ รวมถึงการเตรียมสอบวิชาเฉพาะที่ต้องการเข้าศึกษาในสาขานั้น ๆ',
    icon: (
      <svg
        width={36}
        height={36}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        ></path>
      </svg>
    )
  },
  {
    heading: 'สอนพิเศษ',
    content: 'เป็นการสอนที่ตั้งเป้าหมายเพื่อช่วยเตรียมความพร้อมของนักเรียนในวิชาที่ต้องการพัฒนา โดยมีครูผู้เชี่ยวชาญที่สามารถสอนให้เข้าใจและทำความเข้าใจได้ดียิ่งขึ้น',
    icon: (
      <svg
        width={36}
        height={36}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
        ></path>
      </svg>
    )
  },
  {
    heading: 'การจัดสอบจำลอง',
    content: 'จะช่วยให้นักเรียนมีประสบการณ์ในการสอบจริง โดยจะให้คะแนนและคำแนะนำเพื่อช่วยให้นักเรียนสามารถปรับปรุงประสิทธิภาพการเรียนรู้ได้อย่างมีประสิทธิภาพ',
    icon: (
      <svg
        width={36}
        height={36}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        ></path>
      </svg>
    )
  }
];

const Content = ({ children, ...props }) => {
  return (
    <Text
      fontSize="md"
      textAlign="left"
      lineHeight="1.375"
      fontWeight="400"
      color="gray.500"
      {...props}
    >
      {children}
    </Text>
  );
};

function DottedBox() {
  return (
    <Box position="absolute" left="-45px" top="-30px" height="full" maxW="700px" zIndex={-1}>
      <svg
        color={useColorModeValue('rgba(55,65,81, 0.1)', 'rgba(55,65,81, 0.7)')}
        width="350"
        height="420"
        fill="none"
      >
        <defs>
          <pattern
            id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"></rect>
      </svg>
    </Box>
  );
}

export const Blob = (props) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};


const NavLink = ({ name, path, onClose }) => {
  return (
    <Link
      href={path}
      fontSize='sm'
      lineHeight="inherit"
      color={useColorModeValue('gray.600', 'gray.600')}
      _hover={{
        textDecoration: 'none',
        color: useColorModeValue('blue.500', 'blue.200')
      }}
      onClick={() => onClose()}
    >
      {name}
    </Link>
  );
};

const navLinks = [
  { name: 'About', path: '#about' },
  { name: 'สมัครใช้คลังข้อสอบ', path: '#regisexam' },
  { name: 'สมัครเรียน', path: '#regis' },
  { name: 'Youtube', path: '#youtube' },
  { name: 'Facebook', path: '#facebook' },
  { name: 'Review', path: '#review' },
  { name: 'ข่าวสารสนามสอบ', path: '#calendar' },
];

const HeroSection = () => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider theme={theme} resetCss={false} w="100%">
      <Box px={4} boxShadow={'sm'} as="header"
        bg={useColorModeValue('white', 'gray.800')}
        px={4}
        boxShadow={useColorModeValue(
          '0 4px 6px rgba(160, 174, 192, 0.6)',
          '0 4px 6px rgba(9, 17, 28, 0.9)'
        )}
        position="fixed"
        width="100%"
        zIndex="55"
        top="0">
        <Flex h={16} alignItems="center" justifyContent="space-between" mx="auto">
        <HStack spacing={2} alignItems="center"></HStack>
        <HStack spacing={6} alignItems="center">
          <Image
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={'50px'}
                h={'50px'}
                src={logoImage}
              />
          <Text fontWeight={600} color={'grey.700'}>
            กวดวิชาคิดดี
          </Text>
        </HStack>
          
          <HStack spacing={6} alignItems="center">
            <HStack as="nav" spacing={6} d={{ base: 'none', md: 'flex' }} alignItems="center">
              {navLinks.map((link, index) => (
                <NavLink key={index} {...link} onClose={onClose} />
              ))}
            </HStack>
          </HStack>
          <HStack spacing={2} alignItems="center"></HStack>
          <IconButton
            size="md"
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label="Open Menu"
            d={{ base: 'inherit', md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>

        {/* Mobile Screen Links */}
        {isOpen ? (
          <Box pb={4} d={{ base: 'inherit', md: 'none' }}>
            <Stack as="nav" spacing={2}>
              {navLinks.map((link, index) => (
                <NavLink key={index} {...link} onClose={onClose} />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>


      <Container maxW={'7xl'} id='about'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 30, md: 28 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: '30%',
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'teal.400',
                  zIndex: -1,
                }}>
                Kid D School
              </Text>
              <br />
              <Text as={'span'} color={'teal.400'}>
                กวดวิชาคิดดี
              </Text>
            </Heading>
            <Text color={'gray.500'}>
              เรียนหนังสือได้อย่างเต็มประสิทธิภาพกับกวดวิชาคิดดีของเรา! เรามีทีมครูผู้เชี่ยวชาญที่จะช่วยเตรียมความพร้อมของน้อง ๆ ในการเข้าสู่สถาบันการศึกษาที่มีชื่อเสียง
              <br />
              <br />
              น้องๆที่เรียนในกวดวิชาของเรามักมีผลสอบเข้าสู่สถาบันการศึกษาที่สูงกว่าเฉลี่ยอย่างมาก เพราะเราให้ความสำคัญกับการเรียนรู้และความเข้าใจที่ลึกซึ้ง โดยเราใช้วิธีการสอนที่น่าสนใจ และสร้างบรรยากาศการเรียนรู้ที่น่าสนใจและมีประสิทธิภาพ
              <br />
              <br />
              นอกจากนี้ เรายังมีโปรแกรมสอนที่หลากหลาย เช่น การจัดสอบจำลอง การบริการตัวช่วยเรียนรู้ออนไลน์ และอื่น ๆ ที่จะช่วยให้น้อง ๆ สามารถฝึกฝนและทบทวนวิชาต่าง ๆ ได้อย่างมีประสิทธิภาพ
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
                <a href='/login' target="_blank">
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'teal.700'}
                bgGradient="linear(to-br, #22c1c3, #fdbb2d)"
                _hover={{ bg: 'teal.700' }}>
                เข้าสู่คลังข้อสอบออนไลน์
              </Button></a>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Blob
              w={'150%'}
              h={'150%'}
              position={'absolute'}
              top={'-20%'}
              left={0}
              zIndex={-1}
              color={useColorModeValue('teal.50', 'teal.400')}
            />
            <Box
              position={'relative'}
              height={'300px'}
              rounded={'2xl'}
              boxShadow={'2xl'}
              width={'full'}
              overflow={'hidden'}>
              <Image
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={homeImage}
              />
            </Box>
          </Flex>
        </Stack>
      </Container>

      <Container maxW="6xl" p={{ base: 10, md: 10 }} id="feature">
        <chakra.h3 fontSize="4xl" fontWeight="bold" mb={20} textAlign="center" >
          ดูแลน้อง ๆ เหมือนคนในครอบครัว
        </chakra.h3>
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, xl:3 }} placeItems="center" spacing={10} mb={4}>
          {featuresDetail.map((feature, index) => (
            <Box
              key={index}
              bg={useColorModeValue('gray.100', 'gray.700')}
              p={6}
              rounded="lg"
              textAlign="center"
              pos="relative"
            >
              <Flex
                p={2}
                w="max-content"
                color="white"
                bgGradient="linear(to-br, #22c1c3, #fdbb2d)"
                rounded="md"
                marginInline="auto"
                pos="absolute"
                left={0}
                right={0}
                top="-1.5rem"
                boxShadow="lg"
              >
                {feature.icon}
              </Flex>
              <chakra.h3 fontWeight="semibold" fontSize="2xl" mt={6} color={'gray.600'}>
                {feature.heading}
              </chakra.h3>
              <Text fontSize="md" mt={4} color={'gray.600'}>
                {feature.content}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      <Container maxW="6xl" py={10} id="regisexam">
        <chakra.h2 fontSize="3xl" fontWeight="bold" textAlign="center" mb={5}>
          วิธีสมัครคลังข้อสอบออนไลน์
        </chakra.h2>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 0, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <VStack spacing={4} alignItems="flex-start" mb={{ base: 5, md: 0 }} maxW="md">
              <Box>
                <Text>
                - อีเมล์ ไม่ต้องใช้ของจริงก็ได้
                <br/><br/>
                - จำชื่อผู้ใช้งาน และรหัสผ่าน สำหรับเข้าใช้ระบบ
                <br/><br/>
                - สมัครแล้ว แจ้งครูแนน มานะคะ จะได้เปิดระบบให้
                <br/><br/>
                - เข้าระบบได้แล้ว ไปที่คอร์สทั้งหมด .. สนใจอันไหน ทักมาบอก ครูแนนจะได้ส่งข้อสอบให้ทำ
                <br/><br/>
                - เวลาทำข้อสอบ ให้เลือกที่คอร์สของฉัน
                <br/><br/>
                - ทำข้อสอบแล้วมีคะแนน มีเฉลยละเอียดไว้อธิบาย
                </Text>
              </Box>
          </VStack>
            <iframe
              title='kiddexam'
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Ffb.watch%2FkdZZLezu_S%2F&width=600&show_text=false&appId=659614701303965&height=338" width="600" height="338"
              allowFullScreen
              scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
        </Stack>
      </Container>

      <Container maxW="6xl" py={10} id="regis">
        <chakra.h2 fontSize="3xl" fontWeight="bold" textAlign="center" mb={5}>
          วิธีสมัครเรียน
        </chakra.h2>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 0, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
              bg={useColorModeValue('gray.100', 'gray.700')}
              p={6}
              rounded="lg"
              textAlign="center"
              pos="relative"
            >
              <Image
                boxShadow="lg"
                w="100%"
                h="100%"
                minW={{ base: 'auto' }}
                maxH='500px'
                objectFit="cover"
                src={regisImage}
                rounded="md"
                fallback={<Skeleton />}
              />
            </Box>
        </Stack>
      </Container>

      <Container maxW="6xl" px={{ base: 6, md: 3 }} py={10} my={'6rem'} id="youtube">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="center">
          <Box mr={{ base: 0, md: 5 }} pos="relative">
            <DottedBox />
            <Image
              boxShadow="lg"
              w="100%"
              h="100%"
              minW={{ base: 'auto', md: '30rem' }}
              maxH="20rem"
              objectFit="cover"
              src={youtubeImage}
              rounded="md"
              fallback={<Skeleton />}
            />
          </Box>
          <Stack direction="column" spacing={6} justifyContent="center">
            <chakra.h2 fontSize="3xl" lineHeight={1} fontWeight="bold" textAlign="left">
              Youtube Channel : กวดวิชาคิดดี
            </chakra.h2>
            <Box>
              <Content>
                ช่องยูทูปกวดวิชาคิดดีเป็นช่องยูทูปที่มีการสอนออนไลน์ที่ครอบคลุมไปด้วยความเข้าใจและเป็นเรื่องราว มีการสอนอย่างมืออาชีพและผ่านการคัดกรองแล้วจากทีมงานผู้เชี่ยวชาญ
              </Content>
              <Content mt={4}>
                การเรียนรู้ผ่านช่องยูทูปกวดวิชาคิดดีสามารถช่วยให้คุณได้รับการเรียนรู้ที่เหมาะสมกับสไตล์การเรียนรู้ของคุณ คุณสามารถเรียนรู้ได้ตามที่ต้องการ และมีการบันทึกวิดีโอเพื่อให้คุณสามารถเข้าถึงได้ตลอดเวลาที่คุณต้องการ
              </Content>
              <Content mt={4}>
                นอกจากนี้ ช่องยูทูปกวดวิชาคิดดียังมีความคิดสร้างสรรค์ในการสอนและการเรียนรู้ที่สนุกสนานและน่าติดตาม เช่นการใช้ภาพและแผนภูมิเพื่อช่วยให้เข้าใจเนื้อหาได้ง่ายขึ้น
              </Content>
            </Box>
            <Link href="https://www.youtube.com/@kidd_school/" target="_blank" fontSize="sm" color="blue.400">
              เข้าสู่ Youtube Channel →
            </Link>
          </Stack>
        </Stack>
      </Container>

      <Container maxW="6xl" px={{ base: 6, md: 3 }} py={10} my={'6rem'} id="facebook">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="center">
          <Stack direction="column" spacing={6} justifyContent="center">
            <chakra.h2 fontSize="3xl" lineHeight={1} fontWeight="bold" textAlign="left">
              Facebook Page : กวดวิชาคิดดี
            </chakra.h2>
            <Box>
              <Content>
                เป็นเพจที่เน้นการเรียนรู้และพัฒนาการเด็กอย่างครบวงจร โดยเน้นการเรียนรู้ที่เป็นมิตรกับเด็ก และให้ความสำคัญกับการพัฒนาทักษะเชิงบวก ซึ่งเป็นสิ่งที่สำคัญในการเตรียมความพร้อมให้กับการเรียนรู้ในอนาคตของเด็ก
              </Content>
              <Content mt={4}>
              เพจนี้มีเนื้อหาที่หลากหลายและน่าสนใจ รวมถึงการแบ่งปันความรู้และเทคนิคการเรียนรู้ที่เป็นประโยชน์สำหรับเด็ก นอกจากนี้ยังมีกิจกรรมสนุกสนานต่าง ๆ เช่น การวาดรูปและการเล่นเกมที่เป็นประโยชน์ต่อการพัฒนาทักษะของเด็กอีกด้วย
              </Content>
            </Box>
            <Link href="https://www.facebook.com/theKiddschool/" target="_blank" fontSize="sm" color="blue.400">
              เข้าสู่ Facebook Page →
            </Link>
          </Stack>
          <Box mr={{ base: 0, md: 5 }} pos="relative">
            <DottedBox />
            <Image
              boxShadow="lg"
              w="100%"
              h="100%"
              minW={{ base: 'auto', md: '30rem' }}
              maxH="20rem"
              objectFit="cover"
              src={facebookPageImage}
              rounded="md"
              fallback={<Skeleton />}
            />
          </Box>
        </Stack>
      </Container>

      {/* <Container maxW="6xl" px={{ base: 6, md: 3 }} py={10} my={'6rem'} id="exam">
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="center">
          <Box mr={{ base: 0, md: 5 }} pos="relative">
            <DottedBox />
            <Image
              boxShadow="lg"
              w="100%"
              h="100%"
              minW={{ base: 'auto', md: '30rem' }}
              maxH="20rem"
              objectFit="cover"
              src={kiddExamImage}
              rounded="md"
              fallback={<Skeleton />}
            />
          </Box>
          <Stack direction="column" spacing={6} justifyContent="center">
            <chakra.h2 fontSize="3xl" lineHeight={1} fontWeight="bold" textAlign="left">
              คลังข้อสอบออนไลน์ : KIDD EXAMINATION
            </chakra.h2>
            <Box>
              <Content>
                แหล่งรวมข้อสอบที่ครอบคลุมทุกวิชาที่สอบได้ในระดับการศึกษาต่างๆ ตั้งแต่ชั้นประถมศึกษาจนถึงชั้นมัธยมศึกษาปีที่ 3 โดยมีการจัดเตรียมข้อสอบให้ครบถ้วนและละเอียดถี่ถ้วนเพื่อช่วยให้ผู้ใช้สามารถฝึกฝนความรู้และทักษะการเข้าสอบได้อย่างเต็มประสิทธิภาพ
              </Content>
              <Content mt={4}>
                นอกจากนี้ คลังข้อสอบออนไลน์ยังมีคุณสมบัติที่น่าสนใจอื่นๆ เช่น มีเฉลยข้อสอบให้ดู สามารถดูสถิติของผู้ที่ทำข้อสอบและคะแนนที่ได้ เพิ่มโอกาสให้คุณสามารถฝึกฝนและปรับปรุงตนเองได้อย่างต่อเนื่อง
              </Content>
              <Content mt={4}>
                หากคุณกำลังมองหาเครื่องมือเพื่อเตรียมตัวในการสอบหรืออยากทบทวนความรู้ในการเรียนรู้และต้องการทดสอบตัวเองก่อนเข้าสอบจริง คลังข้อสอบออนไลน์อาจเป็นตัวช่วยที่ดีสำหรับคุณ
              </Content>
            </Box>
            <Link href="/login" target="_blank" fontSize="sm" color="blue.400">
              เข้าสู่ KIDD EXAMINATION →
            </Link>
          </Stack>
        </Stack>
      </Container> */}

      <Box overflow="hidden">
        <svg
          fill={useColorModeValue('#f7fafc', '#171923')}
          width="150%"
          height="56px"
          transform="scale(-1,1)"
          filter="drop-shadow(10px 5px 5px rgba(0, 0, 0, 0.05))"
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 
            250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 
            3V0H0v27.35a600.21 600.21 0 00321.39 29.09z`}
          ></path>
        </svg>
      </Box>

      <Container maxW="6xl" p={{ base: 10, md: 10 }} id="review">
        <chakra.h3 fontSize="3xl" fontWeight="bold" mb={10} textAlign="center">
          Review จากนักเรียนของเรา
        </chakra.h3>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 0, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
            <Box
              bg={useColorModeValue('gray.100', 'gray.700')}
              p={6}
              rounded="lg"
              textAlign="center"
              pos="relative"
              minW={{ base: '90%' }}
            >
              <div class='embedsocial-album' data-ref="c1ae163ab3cff2fce41a71f418245ba6954b9fa5"></div>
            </Box>
        </Stack>
      </Container>

      <Container maxW="6xl" py={10} id="calendar">
        <chakra.h2 fontSize="3xl" fontWeight="bold" textAlign="center" mb={5}>
          ข่าวสารสนามสอบ
        </chakra.h2>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 0, md: 3 }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
              bg={useColorModeValue('gray.100', 'gray.700')}
              p={6}
              rounded="lg"
              textAlign="center"
              pos="relative"
              minW={{ base: '90%' }}
            >
              <div class='embedsocial-album' data-ref="b9ba18d6eeb96a2ea38858366b0ddbac8bb3b2d9"></div>
            </Box>
        </Stack>
      </Container>

      <Container maxW="5xl" p={{ base: 5, md: 8 }} my={{xl: 20}}>
        {testimonials.map((obj, index) => (
          <Fragment key={index}>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              bgGradient="linear(to-br, #22c1c3, #fdbb2d)"
              spacing={{ base: 0, sm: 10 }}
              p={{ base: 4, sm: 10 }}
              rounded="lg"
              justify="center"
            >

              <Stack direction="column" spacing={4} textAlign="left" maxW="4xl">
                <Icon as={ImQuotesLeft} w={10} h={10} color="gray.700" />
                <Text fontSize="md" fontWeight="medium" color="gray.100">
                  {obj.content}
                </Text>
                <Stack alignItems={{ base: 'center', sm: 'flex-start' }} spacing={0}>
                  <Avatar
                    size="xl"
                    showBorder={true}
                    borderColor="green.400"
                    name="avatar"
                    src={obj.image}
                    d={{ base: 'block', sm: 'none' }}
                  />
                  <Text fontWeight="bold" fontSize="lg">
                    {obj.name}
                  </Text>
                  <Text fontWeight="medium" fontSize="sm" color="gray.600">
                    {obj.position}, {obj.company}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Fragment>
        ))}
      </Container>

      <Box
        as="footer"
        bg="gray.300"
        borderTop="1px solid"
        borderColor="gray.300"
        py="2.5rem"
        fontSize="0.875rem"
      >
        <Box
          maxW="64rem"
          marginX="auto"
          pb="2rem"
          px={10}
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          <Flex flexWrap="wrap" alignItems="start" justify="space-between">
            <Box w={{ base: '100%', sm: '50%', md: 'max-content' }} mb={{ base: '1.5rem', lg: '0' }}>
              <Heading as="h4" color="gray.600" mb="0.5rem" fontSize="0.875rem" fontWeight="600">
                โรงเรียนกวดวิชาคิดดี
              </Heading>
              <Text color={'gray.600'}>
                27/6 ถ.นครสวรรค์ อ.เมือง , Maha Sarakham, Thailand, 44000
              </Text>
              <Text color={'gray.600'}>
                +66 84 515 5165
              </Text>
            </Box>
            <Box w={{ base: '100%', sm: '50%', md: 'max-content' }} mb={{ base: '1.5rem', lg: '0' }}>
                <a href='https://line.me/R/ti/p/@กวดวิชาคิดดี' target="_blank">
                  <Box p={2}>
                    <Box
                      bgImage={lineImage}
                      w='30px'
                      h='30px'
                      bgSize='cover'
                      bgPosition='50%'
                      position='absolute'
                      borderBottomLeftRadius='20px'>
                    </Box>
                    <Text color={'gray.600'} pl='10'>
                      @กวดวิชาคิดดี
                    </Text>
                  </Box>
                </a>
                <a href='https://www.facebook.com/theKiddschool/' target="_blank">
                  <Box p={2}>
                    <Box
                      bgImage={facebookImage}
                      w='30px'
                      h='30px'
                      bgSize='cover'
                      bgPosition='50%'
                      position='absolute'
                      borderBottomLeftRadius='20px'>
                    </Box>
                    <Text color={'gray.600'} pl='10'>
                      กวดวิชาคิดดี
                    </Text>
                  </Box>
                </a>
            </Box>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default HeroSection;