// Chakra imports
import { Flex } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GET_QUESTION_BY_EXAM } from 'store/questions';
import Authors from "./components/Authors";

function Table() {
  const dispatch = useDispatch()
  const { id } = useParams()

  const initFetch = useCallback(() => {
    dispatch(GET_QUESTION_BY_EXAM(id))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
  const lists = useSelector((state) => state.questions.question.questions)

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Authors
        title={"รายละเอียดข้อสอบ"}
        captions={["ข้อที่", "โจทย์", "", ""]}
        data={lists}
      />
    </Flex>
  );
}

export default Table;
