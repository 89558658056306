// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_BY_ID } from "store/users";
import Header from "./components/Header";
import HistoryExam from "./components/HistoryExam";
import ProfileInformation from "./components/ProfileInformation";

function Profile() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_USER_BY_ID(Cookies.get('user_id')))
  }, [dispatch])
  useEffect(() => {
    initFetch()
  }, [initFetch])

  const data = useSelector((state) => state.users.user)

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={require(`assets/img/avatars/g${Math.floor((Math.random()*10) + 1)}.png`)}
        fullname={`${data?.firstname} ${data?.lastname}`}
        username={data?.username}
      />
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap={{ sm: "0px", xl: "22px" }}>
        <GridItem colSpan={1}>
          <ProfileInformation
            title={"ข้อมูลนักเรียน"}
            fullname={`${data?.firstname} ${data?.lastname}`}
            classroom={data?.class}
            school={data?.school}
            mobile={data?.mobile}
            email={data?.email}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <HistoryExam
            title={"ประวัติการทำข้อสอบ"}
            captions={["ชื่อชุดข้อสอบ", "คอร์สเรียน", "วันที่ทำข้อสอบ", "คะแนน", "เปอร์เซ็นต์"]}
            data={data?.history}
            maxHeight='500px'
            role='user'
          />
        </GridItem>
      </Grid>
    </Flex>
  );
}

export default Profile;
