import { Box, Button, Flex, FormControl, FormLabel, Input, Switch, Text, Textarea, useColorModeValue } from '@chakra-ui/react'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { CREATE_COURSE } from 'store/courses'
import { CLASS, SUBJECT } from 'variables/enumObject'
import * as Yup from 'yup'

function AddCourse() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white')
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: '#E2E8F0',
      minHeight: '3rem',
      marginBottom: '24px'
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }),
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('บังคับกรอก')
  })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      status: false,
      is_free: false
    },
    onSubmit: (values, { setSubmitting }) => {
      values = {
        ...values,
        name: values.name.replace(/'/g, '’'),
        description: values.description.replace(/'/g, '’')
      }
      dispatch(CREATE_COURSE(values))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/courses')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader>
            <Flex justify="space-between" align="center" minHeight="60px" w="100%">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
              เพิ่มคอร์สเรียน
              </Text>
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                <small fontSize="sm">ปิดใช้งาน</small>
                <Switch size="lg" colorscheme="teal" mx="10px" id="status" name="status"
                  onChange={event =>formik.setFieldValue("status", event.target.checked)} />
                <small fontSize="sm">เปิดใช้งาน</small>
              </Text>
            </Flex>
          </CardHeader>
          <CardBody p="20px">
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                ชื่อคอร์สเรียน
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="text"
                mb="24px"
                size="lg"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ''}
              />
              <Flex gap="5">
                <Box flex="1">
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    วิชา
                  </FormLabel>
                  <Select
                    options={SUBJECT}
                    id="subject"
                    name="subject"
                    placeholder="เลือกวิชา"
                    styles={customStyles}
                    onChange={selectedOption =>
                      formik.setFieldValue("subject", selectedOption.label)
                    }
                    onBlur={formik.handleBlur} />
                </Box>
                <Box flex="1">
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    ชั้นเรียน
                  </FormLabel>
                  <Select
                    options={CLASS}
                    id="class"
                    name="class"
                    placeholder="เลือกชั้นเรียน"
                    styles={customStyles}
                    onChange={selectedOption =>
                      formik.setFieldValue("class", selectedOption.label)
                    }
                    onBlur={formik.handleBlur} />
                </Box>
              </Flex>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                รายละเอียด
              </FormLabel>
              <Textarea
                id="description"
                name="description"
                fontSize="sm"
                ms="4px"
                borderRadius="15px"
                type="textarea"
                mb="24px"
                size="lg"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description || ''}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                ประเภทคอร์ส
              </FormLabel>
              <Text fontSize="lg" color={textColor} fontWeight="bold" mb="24px">
                <small fontSize="sm">เสียเงิน</small>
                <Switch size="lg" colorscheme="teal" mx="10px" id="is_free" name="is_free"
                  onChange={event =>formik.setFieldValue("is_free", event.target.checked)} />
                <small fontSize="sm">ฟรี</small>
              </Text>
              <Button
                type="submit"
                bg="teal.300"
                fontSize="1rem"
                color="white"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                disabled={Object.keys(formik.errors).length != 0 ? true : false}
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                สร้างคอร์สเรียน
              </Button>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  )
}

export default AddCourse
