import { Box, Button, Image, useColorModeValue } from "@chakra-ui/react";
function ButtonOption(props) {
  const { item, handleAnswerButtonClick, answer_id, question_id } = props;
  const pathUrl = process.env.REACT_APP_UPLOAD_PATH
  const titleColor = useColorModeValue("teal", "teal");

  let colorbutton = 'limegreen';
  switch (item.id) {
    case '1':
      colorbutton = 'darkorange';
      break;
    case '2':
      colorbutton = 'deeppink';
      break;
    case '3':
      colorbutton = 'dodgerblue';
      break;
  }
  // let colorbutton = 'teal'

  return (
    <Box flex='1' key={item.id}>
        <Button
        onClick={() => handleAnswerButtonClick(parseInt(item.id)===parseInt(answer_id), question_id)}
        type='submit'
        display='block'
        whiteSpace='inherit'
        variant='outline'
        colorScheme={colorbutton}
        color={colorbutton}
        bg='white'
        borderColor={colorbutton}
        fontSize={{ base: "1.2rem", md: "1.2rem" }}
        fontWeight='bold'
        ButtonOption
        border='4px'
        w='100%'
        h='100%'
        py='0.8rem'
        mb='1rem'
        _hover={{
            xl: {
              color: `${colorbutton}.200`,
              borderColor: `${colorbutton}.200`
            }
        }}
        _active={{
          color: `${colorbutton}.200`,
          borderColor: `${colorbutton}.200`
        }}>
            {(item.name === '.') ? '' : item.name}
            {(item.img !== undefined && item.img !== '' && item.img !== null && item.img !== 'null')
              && <Image maxHeight='300px' m='auto' src={pathUrl + item.img} />}
        </Button>
    </Box>
  );
}

export default ButtonOption;
