// Chakra imports
import {
  Grid, useColorModeValue
} from "@chakra-ui/react";
import eng from "assets/img/eng.jpg";
import math from "assets/img/math.jpg";
import sci from "assets/img/sci.jpg";
import social from "assets/img/social.jpg";
import thai from "assets/img/thai.jpg";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_COURSE_BY_USER_ID } from "store/courses";
import ExamCard from "./ExamCard";

const Exams = ({ title, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [searching, setSearching] = useState(false)
  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_COURSE_BY_USER_ID(Cookies.get('user_id')))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  const lists = useSelector((state) => state.courses.listsUser)

  const imageSubject = (subject) => {
    if (subject == 'คณิตศาสตร์') {
      return math
    }  else if (subject == 'วิทยาศาสตร์') {
      return sci
    } else if (subject == 'สังคมศึกษา') {
      return social
    } else if (subject == 'ภาษาอังกฤษ') {
      return eng
    } else if (subject == 'ภาษาไทย') {
      return thai
    }
  }

  const courseFree = (item) => {
    if (item.is_free) {
      return true;
    } else {
      return false;
    }
  }

  const coursePaid = (item) => {
    if (!item.is_free) {
      return true;
    } else {
      return false;
    }
  }

  const fetchData = useCallback((searchInput) => {
    setSearching(true)
    dispatch(GET_COURSE_BY_USER_ID(`${Cookies.get('user_id')}&search=${searchInput}`))
  }, [dispatch])

  if(Array.isArray(lists) && (lists.length == 0) && !searching) {
    return (
      <Card p='16px' my='24px'>
        <CardBody px='5px'>
          <div>ไม่มีคอร์สเรียนที่สมัคร</div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card p='16px' my='24px'>
      <CardHeader mb='1rem'>
        <SearchBar fetchData={fetchData} />
      </CardHeader>
      <CardBody px='5px'>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
          templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
          gap='24px'>
          {lists.filter(courseFree).map((item)=> {
            if (item.course_status) {
              return (
                <ExamCard
                  key={item.course_id}
                  image={imageSubject(item.subject)}
                  name={item.course_name}
                  category={`${item.subject} ${item.class}`}
                  description={item.course_description}
                  id={item.course_id}
                />
              )
            }
          })}
        </Grid>
      </CardBody>
    </Card>
  );
};

export default Exams;
