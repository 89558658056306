import {
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { STATUS } from "variables/enumObject";

function TablesTableRow(props) {
  const { logo, name, username, classroom, school, status, print, date, id } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const history = useHistory();

  const routeEdit = () =>{
    let path = `/admin/users/${id}/edit`;
    history.push(path);
  }

  const routeEditCourse = () =>{
    let path = `/admin/users/course/${id}/edit`;
    history.push(path);
  }

  const routeStat = () =>{
    let path = `/admin/users/stat/${id}/`;
    history.push(path);
  }

  let statusLabel = STATUS.filter(x => x.value === status)[0].label

  return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {username}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {classroom}
          </Text>
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {school}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Badge
          bg={status === "Active" ? "green.400" : status === "Pending Approval" ? "orange.400" : "gray.400"}
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {statusLabel}
        </Badge>
      </Td>
      <Td>
        <Text
          fontSize="16px"
          color={(print?.questions !== null && print?.questions === 'true') ? "green" : "gray"}
          fontWeight="bold"
          minWidth="75px"
        >
          {(print?.questions !== null && print?.questions === 'true') ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
        </Text>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeEdit}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            แก้ไข
          </Text>
        </Button>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeEditCourse}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            คอร์สเรียน
          </Text>
        </Button>
      </Td>
      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeStat}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            สถิติ
          </Text>
        </Button>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
