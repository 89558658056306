// Chakra imports
import {
  Box,
  Button, Flex, FormControl,
  FormLabel,
  Image,
  Input, Radio, RadioGroup,
  Stack, Text, Textarea, useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GET_QUESTION_BY_ID, UPDATE_QUESTION } from "store/questions";
import * as Yup from 'yup';

function EditQuestion() {
  const textColor = useColorModeValue("gray.700", "white");
  const [numOption, setNumOption] = useState('2')
  const dispatch = useDispatch()
  const { id, questionId } = useParams()
  const [imgQuestionCurrent, setImgQuestionCurrent] = useState('')
  const [imgAnswerCurrent, setImgAnswerCurrent] = useState('')
  const [imgOptionsCurrent, setImgOptionsCurrent] = useState([])
  const [imgQuestion, setImgQuestion] = useState({})
  const [imgOption1, setImgOption1] = useState({})
  const [imgOption2, setImgOption2] = useState({})
  const [imgOption3, setImgOption3] = useState({})
  const [imgOption4, setImgOption4] = useState({})
  const [imgAnswer, setImgAnswer] = useState({})
  const [imgOptions, setImgOptions] = useState([])

  const validationSchema = Yup.object({
    question: Yup.string().required('บังคับกรอก')
  })

  const history = useHistory()
  const pathUrl = process.env.REACT_APP_UPLOAD_PATH

  const formik = useFormik({
    initialValues: {
      answer_id: '1'
    },
    onSubmit: (values, { setSubmitting }) => {
      let options = []
      Object.entries(values).map(([key, value]) => {
        if(key.startsWith('option')) {
          let id = parseInt(key.split('_')[1])
          let img = undefined
          if (id === 1 && imgOption1.file === undefined) img = imgOptionsCurrent[id - 1]
          if (id === 2 && imgOption2.file === undefined) img = imgOptionsCurrent[id - 1]
          if (id === 3 && imgOption3.file === undefined) img = imgOptionsCurrent[id - 1]
          if (id === 4 && imgOption4.file === undefined) img = imgOptionsCurrent[id - 1]

          options.push({
            "id": parseInt(id),
            "name": value.replaceAll("'","’"),
            "img": img
          })
        }
      })

      const dataForm = new FormData()
      dataForm.append('exam_id', parseInt(id))
      dataForm.append('question', values.question.replaceAll("'","’"))
      dataForm.append('options', JSON.stringify(options))
      dataForm.append('answer_id', parseInt(values.answer_id))
      dataForm.append('answer_desc', values.answer_desc.replaceAll("'","’") || '')
      dataForm.append('question_img', (imgQuestionCurrent === '') ? '' : imgQuestionCurrent)
      dataForm.append('answer_img', (imgAnswerCurrent === '') ? '' : imgAnswerCurrent)

      if (imgQuestion.file) dataForm.append('files', imgQuestion.file)
      if (imgOption1.file) dataForm.append('files', imgOption1.file)
      if (imgOption2.file) dataForm.append('files', imgOption2.file)
      if (imgOption3.file) dataForm.append('files', imgOption3.file)
      if (imgOption4.file) dataForm.append('files', imgOption4.file)
      if (imgAnswer.file) dataForm.append('files', imgAnswer.file)

      dispatch(UPDATE_QUESTION(questionId, dataForm))
      .then(data => {
        setSubmitting(false)
        history.push(`/admin/exams/${id}/question/`)
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  const onFileChangeQuestion = (event) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const myNewFile = new File([file], 'question', { type: file.type })
      const reader = new FileReader()
      reader.onload = (e) => {
        setImgQuestionCurrent('')
        setImgQuestion({ file: myNewFile, base64: e.target.result })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const removeQuestion = () => {
    setImgQuestion({})
    setImgQuestionCurrent('')
  }

  const onFileChangeOptions = (event, index) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const name = 'opt' + (index + 1)
      const myNewFile = new File([file], name, { type: file.type })
      const reader = new FileReader()
      reader.onload = (e) => {
        imgOptions[index] = { index: index, base64: e.target.result }
        let optionCurrentURL = imgOptionsCurrent
        switch (index + 1) {
          case 1:
            optionCurrentURL[0] = ''
            setImgOptionsCurrent(optionCurrentURL)
            setImgOption1({ file: myNewFile, base64: e.target.result, name: name })
            break
          case 2:
            optionCurrentURL[1] = ''
            setImgOptionsCurrent(optionCurrentURL)
            setImgOption2({ file: myNewFile, base64: e.target.result, name: name })
            break
          case 3:
            optionCurrentURL[2] = ''
            setImgOptionsCurrent(optionCurrentURL)
            setImgOption3({ file: myNewFile, base64: e.target.result, name: name })
            break
          default:
            optionCurrentURL[3] = ''
            setImgOptionsCurrent(optionCurrentURL)
            setImgOption4({ file: myNewFile, base64: e.target.result, name: name })
        }
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const removeOptions = (index) => {
    imgOptions[index] = {}
    let optionCurrentURL = imgOptionsCurrent
    switch (index + 1) {
      case 1:
        setImgOption1({})
        optionCurrentURL[0] = ''
        setImgOptionsCurrent(optionCurrentURL)
        break
      case 2:
        setImgOption2({})
        optionCurrentURL[1] = ''
        setImgOptionsCurrent(optionCurrentURL)
        break
      case 3:
        setImgOption3({})
        optionCurrentURL[2] = ''
        setImgOptionsCurrent(optionCurrentURL)
        break
      default:
        setImgOption4({})
        optionCurrentURL[3] = ''
        setImgOptionsCurrent(optionCurrentURL)
    }
  }

  const onFileChangeAnswer = (event) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const myNewFile = new File([file], 'answer', { type: file.type })
      // setImgAnswer(myNewFile)
      const reader = new FileReader()
      reader.onload = (e) => {
        setImgAnswerCurrent('')
        setImgAnswer({ file: myNewFile, base64: e.target.result })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const removeAnswer = () => {
    setImgAnswer({})
    setImgAnswerCurrent('')
  }

  const initFetch = useCallback(() => {
    dispatch(GET_QUESTION_BY_ID(questionId)).then((data) => {
      setImgQuestionCurrent((data.question_img === 'null') ? '' : data.question_img)
      formik.setFieldValue('question', data.question)
      const options = JSON.parse(data.options);
      setNumOption(options.length)
      let optionCurrentURL = []
      options.map((item) => {
        formik.setFieldValue(`option_${item.id}`, item.name)
        optionCurrentURL.push(item.img)
      })
      setImgOptionsCurrent(optionCurrentURL)
      formik.setFieldValue('answer_id', data.answer_id)
      setImgAnswerCurrent(data.answer_img)
      formik.setFieldValue('answer_desc', data.answer_desc)
    })
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              แก้ไขคำถาม
            </Text>
          </CardHeader>
          <CardBody p="20px">
            <FormControl>
              <FormLabel ms='4px' fontWeight='semibold'>
                คำถาม
              </FormLabel>
              <Flex gap='5'>
                <Box flex='5'>
                  <Textarea
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    mb='10px'
                    size='lg'
                    minHeight='100px'
                    name="question"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.question || ''}
                  />
                  <Text style={{position: 'relative'}}>
                    <Button mb='24px' colorscheme='teal' variant='outline'>
                      <Text fontSize="sm" fontWeight="semibold">
                        แก้ไขรูปภาพ
                      </Text>
                    </Button>
                    <input type='file' id='file'
                      onChange={onFileChangeQuestion}
                      accept='image/jpg, image/png'
                      style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}}
                    />
                    {(imgQuestion.base64 || imgQuestionCurrent) &&
                      <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={removeQuestion}>
                        <Text fontSize="sm" fontWeight="semibold" color="red.500">
                          ลบรูปภาพ
                        </Text>
                      </Button>
                    }
                  </Text>
                </Box>
                <Box flex='2'>
                  {imgQuestionCurrent &&
                    <Box mb='20px' position='relative' borderRadius='15px'>
                      <Image maxHeight='200px' m='auto' borderRadius='15px' src={pathUrl + imgQuestionCurrent} />
                      <Box
                        w='100%'
                        h='100%'
                        position='absolute'
                        top='0'
                        borderRadius='15px'></Box>
                    </Box>
                  }
                  {imgQuestion.base64 &&
                    <Box mb='20px' position='relative' borderRadius='15px'>
                      <Image maxHeight='200px' src={imgQuestion.base64} borderRadius='15px' />
                      <Box
                        w='100%'
                        h='100%'
                        position='absolute'
                        top='0'
                        borderRadius='15px'></Box>
                    </Box>
                  }
                </Box>
              </Flex>


              <FormLabel ms='4px' fontWeight='semibold'>
                จำนวนตัวเลือก
              </FormLabel>
              <RadioGroup
                mb='24px' size='md'
                onChange={setNumOption}
                value={`${numOption}`}>
                <Stack spacing={5} direction='row' mb='24px'>
                  <Radio value='2'>2</Radio>
                  <Radio value='3'>3</Radio>
                  <Radio value='4'>4</Radio>
                </Stack>
              </RadioGroup>

              {Array(parseInt(numOption)).fill(1).map((el, i) => {
                return (
                <Flex gap='5' key={i}>
                  <Box flex='1'>
                    <FormLabel ms='4px' fontWeight='semibold'>
                      ตัวเลือกที่ {i+1}
                    </FormLabel>
                  </Box>
                  <Box flex='5'>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      borderRadius='15px'
                      type='text'
                      mb='24px'
                      size='lg'
                      mr='20px'
                      name={`option_${i+1}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={eval(`formik.values.option_${i+1}`)}
                    />
                    {imgOptionsCurrent[i] &&
                      <Box mb='20px' position='relative' borderRadius='15px'>
                        <Image maxHeight='200px' m='auto' borderRadius='15px' src={pathUrl + imgOptionsCurrent[i]} />
                        <Box
                          w='100%'
                          h='100%'
                          position='absolute'
                          top='0'
                          borderRadius='15px'></Box>
                      </Box>
                    }
                    {imgOptions[i]?.base64 &&
                      <Box mb='20px' position='relative' borderRadius='15px'>
                        <Image maxHeight='200px' src={imgOptions[i].base64} borderRadius='15px' />
                        <Box
                          w='100%'
                          h='100%'
                          position='absolute'
                          top='0'
                          borderRadius='15px'></Box>
                      </Box>
                    }
                  </Box>
                  <Box flex='2'>
                    <Text style={{position: 'relative'}}>
                      <Button mb='24px' colorscheme='teal' variant='outline'>
                        <Text fontSize="sm" fontWeight="semibold">
                          ใส่รูปภาพ
                        </Text>
                      </Button>
                      <input type='file' id='file'
                        onChange={(e) => onFileChangeOptions(e, i)}
                        accept='image/jpg, image/png'
                        style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}}
                        />
                        {(imgOptions[i]?.base64 || imgOptionsCurrent[i]) &&
                          <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={() => removeOptions(i)}>
                            <Text fontSize="sm" fontWeight="semibold" color="red.500">
                              ลบรูปภาพ
                            </Text>
                          </Button>
                        }
                    </Text>
                  </Box>
                </Flex>
                )}
              )}

              <FormLabel ms='4px' fontWeight='semibold'>
                คำตอบที่ถูกต้อง
              </FormLabel>
              <RadioGroup mb='24px' size='md'
                onChange={selectedOption =>
                  formik.setFieldValue("answer_id", selectedOption)
                }
                value={formik.values.answer_id || ''} >
                <Stack spacing={5} direction='row' mb='24px'>
                  {Array(parseInt(numOption)).fill(1).map((el, i) =>
                    <Radio key={i} value={`${i+1}`}>ตัวเลือกที่ {i+1}</Radio>
                  )}
                </Stack>
              </RadioGroup>
              <FormLabel ms="4px" fontWeight='semibold'>
                รายละเอียดคำตอบ
              </FormLabel>
              <Flex gap='5'>
                <Box flex='5'>
                  <Textarea
                    id="answer_desc"
                    name="answer_desc"
                    fontSize="sm"
                    ms="4px"
                    borderRadius="15px"
                    type="textarea"
                    mb="24px"
                    size="lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.answer_desc || ''}
                  />
                  <Text style={{position: 'relative'}}>
                    <Button mb='24px' colorscheme='teal' variant='outline'>
                      ใส่รูปภาพ
                    </Button>
                    <input type='file' id='file'
                      onChange={onFileChangeAnswer}
                      accept='image/jpg, image/png'
                      style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}}
                      />
                    {(imgAnswer.base64 || imgAnswerCurrent) &&
                      <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={removeAnswer}>
                        <Text fontSize="sm" fontWeight="semibold" color="red.500">
                          ลบรูปภาพ
                        </Text>
                      </Button>
                    }
                  </Text>
                </Box>
                <Box flex='2'>
                  {imgAnswerCurrent &&
                    <Box mb='20px' position='relative' borderRadius='15px'>
                      <Image maxHeight='200px' m='auto' borderRadius='15px' src={pathUrl + imgAnswerCurrent} />
                      <Box
                        w='100%'
                        h='100%'
                        position='absolute'
                        top='0'
                        borderRadius='15px'></Box>
                    </Box>
                  }
                  {imgAnswer.base64 &&
                    <Box mb='20px' position='relative' borderRadius='15px'>
                      <Image maxHeight='200px' src={imgAnswer.base64} borderRadius='15px' />
                      <Box
                        w='100%'
                        h='100%'
                        position='absolute'
                        top='0'
                        borderRadius='15px'></Box>
                    </Box>
                  }
                </Box>
              </Flex>
            <Flex>
                <Button
                  type='submit'
                  bg='teal.300'
                  fontSize='20px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  mr='10px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}>
                  แก้ไขคำถาม
                </Button>
              </Flex>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default EditQuestion;
