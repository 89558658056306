// Chakra imports
import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { GET_COURSES } from 'store/courses';
import { DELETE_EXAM, GET_EXAM_BY_ID, UPDATE_EXAM } from 'store/exams';
import { DURATION } from 'variables/enumObject';
import * as Yup from 'yup';

function EditExam() {
  const dispatch = useDispatch()
  const textColor = useColorModeValue('gray.700', 'white');
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');
  const { id } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: '#E2E8F0',
      minHeight: '3rem',
      marginBottom: '24px'
    }),
    option: (styles, state) => ({
      ...styles,      
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }), 
  }

  const listCourse = useSelector((state) => state.courses.lists)

  const optionsCourse = Object.values(listCourse).map((item)=> ({
    value: item.id,
    label: item.name
  }))

  const validationSchema = Yup.object({
    name: Yup.string().required('บังคับกรอก')
  })

  const history = useHistory()

  const confirmDelete = () => {
    onOpen()
  }

  const deleteExam = () => {
    dispatch(DELETE_EXAM(id))
    .then(data => {
      onClose()
      history.push(`/admin/exams`)
    })
    .catch(e => {
      onClose()
    })
  }

  const formik = useFormik({
    initialValues: {
      status: false
    },
    onSubmit: (values, { setSubmitting }) => {
      let mapCourseID = values.course_id.map((i) => i.value)
      values = {
        ...values,
        course_id : mapCourseID.toString()
      }
      dispatch(UPDATE_EXAM(
        id,
        {
          name: values.name,
          course_id: values.course_id,
          quantity: values.quantity,
          duration: values.duration.value,
          description: values.description
        }
      ))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/exams')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  const initFetch = useCallback(() => {
    dispatch(GET_COURSES(''))
    dispatch(GET_EXAM_BY_ID(id)).then((data) => {
      let courseIdSplit = data.course_id.toString().split(",")
      const filteredCourseId = [];
      courseIdSplit.forEach(filterValue => {
        filteredCourseId.push(optionsCourse.filter(val => val.value === parseInt(filterValue))[0]);
      });
      formik.setFieldValue('name', data.name)
      formik.setFieldValue('course_id', filteredCourseId)
      formik.setFieldValue('duration', DURATION.filter(x => x.value === data.duration)[0])
      formik.setFieldValue('quantity', data.quantity)
      formik.setFieldValue('description', data.description)
    })
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  return (
    <Flex direction='column' pt={{ base: '120px', md: '75px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
          <CardHeader>
            <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                แก้ไขข้อสอบ
              </Text>
            </Flex>
          </CardHeader>
          <CardBody p='20px'>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                ชื่อข้อสอบ
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                mb='24px'
                size='lg'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ''}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                คอร์สเรียน
              </FormLabel>
              <Select 
                isMulti
                id='course_id' 
                name='course_id'
                placeholder='เลือกคอร์สเรียน'
                options={optionsCourse}
                styles={customStyles}
                value={formik.values.course_id || ''}
                onChange={(selectedOption) => {
                  let e = { target: { name: 'course_id', value: selectedOption } };
                  formik.handleChange(e);
              }}
                onBlur={formik.handleBlur}  />
              <Flex gap='5'>
                <Box flex='1'>
                  <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                    ระยะเวลาแต่ละข้อ
                  </FormLabel>
                  <Select 
                    options={DURATION}
                    id='duration'
                    name='duration' 
                    placeholder='เลือกเวลา' 
                    styles={customStyles}
                    value={formik.values.duration || ''}
                    onChange={selectedOption => {
                      formik.setFieldValue('duration', selectedOption)
                    }}
                    onBlur={formik.handleBlur} />
                </Box>
              </Flex>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                รายละเอียด
              </FormLabel>
              <Textarea
                id='description'
                name='description'
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='textarea'
                mb='24px'
                size='lg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description == 'null' ? '' : formik.values.description || ''}
              />
              <Button
                type='submit'
                bg='teal.300'
                fontSize='20px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                isDisabled={!formik.values.name || formik.values.name === ''}
                _hover={{
                  bg: 'teal.200',
                }}
                _active={{
                  bg: 'teal.400',
                }}>
                แก้ไขข้อสอบ
              </Button>
              <Button
                onClick={confirmDelete}
                bg='red.300'
                fontSize='20px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                _hover={{
                  bg: 'red.200',
                }}
                _active={{
                  bg: 'red.400',
                }}>
                ลบชุดข้อสอบ
              </Button>
            </FormControl>
          </CardBody>
        </Card>
        <Modal isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box w="100%" align='center'>
                ต้องการลบชุดข้อสอบ {formik.values.name}
              </Box>
            </ModalHeader>
            <ModalFooter>
              <Box w="100%" align='center'>
                <Button colorScheme="red" variant='outline' onClick={deleteExam}>
                  ลบชุดข้อสอบ
                </Button>
                <Button ml="15px" colorScheme="teal" onClick={onClose}>
                  ยกเลิก
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </form>
      
    </Flex>
  );
}

export default EditExam;
