import { SearchIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, children, fetchData, ...rest } = props;
  // Chakra Color Mode
  const mainTeal = useColorModeValue("teal.300", "teal.300");
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("white", "gray.800");
  const [searchInput, setSearchInput] = useState('');

  const searchItems = (event) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    fetchData(searchInput)
  }, [searchInput])

  return (
    <InputGroup
      bg={inputBg}
      borderRadius="15px"
      w="200px"
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          ></IconButton>
        }
      />
      <Input
        fontSize="xs"
        py="11px"
        placeholder="ค้นหา..."
        borderRadius="inherit"
        onChange={searchItems}
      />
    </InputGroup>
  );
}
