import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "../utils/vfs_fonts"

const pathUrl = process.env.REACT_APP_UPLOAD_PATH

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  }
}

export const getBase64ImageFromURL = (url) => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.setAttribute("crossOrigin", "anonymous")
    img.onload = () => {
      let canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      let ctx = canvas.getContext("2d")
      ctx.drawImage(img, 0, 0)
      let dataURL = canvas.toDataURL("image/png")
      resolve(dataURL)
    }
    img.onerror = error => {
      reject(error)
    }
    img.src = url
  })
}

export const pdfQuestions = async (data) => {
  let doc = []
  doc.push('\n')
  for (let i = 0; i < data.questions.length; i++) {
    const questions = data.questions[i]

    doc.push({ text: i + 1 + '. ' + questions.name.replace(/\n/g, "").replace(/\r/g, "") })
    if (questions?.image !== undefined && questions?.image !== null && questions?.image !== '' && questions?.image !== 'null') {
      doc.push({
        image: await getBase64ImageFromURL(pathUrl + questions?.image),
        alignment: 'center',
        maxWidth: 250,
        maxHeight: 180
      })
    }

    const option1 = questions.options.filter(x => parseInt(x.id) === 1)[0]
    const option2 = questions.options.filter(x => parseInt(x.id) === 2)[0]
    const option3 = questions.options.filter(x => parseInt(x.id) === 3)[0]
    const option4 = questions.options.filter(x => parseInt(x.id) === 4)[0]
    const img1 = (option1?.img !== 'null' && option1?.img !== null && option1?.img !== '' && option1?.img !== undefined) ? await getBase64ImageFromURL(pathUrl + option1?.img) : null
    const img2 = (option2?.img !== 'null' && option2?.img !== null && option2?.img !== '' && option2?.img !== undefined) ? await getBase64ImageFromURL(pathUrl + option2?.img) : null
    const img3 = (option3?.img !== 'null' && option3?.img !== null && option3?.img !== '' && option3?.img !== undefined) ? await getBase64ImageFromURL(pathUrl + option3?.img) : null
    const img4 = (option4?.img !== 'null' && option4?.img !== null && option4?.img !== '' && option4?.img !== undefined) ? await getBase64ImageFromURL(pathUrl + option4?.img) : null

    doc.push({
      columns: [
        { text: '1. ' + ((option1?.name === '.') ? '' : option1?.name || ''), style: 'choice' },
        { text: '2. ' + ((option2?.name === '.') ? '' : option2?.name || ''), style: 'choice' }
      ]
    })

    if (img1 !== null && img2 !== null) {
      doc.push({
        columns: [
          { image: img1, fit: [80, 80], style: 'choiceImage' },
          { image: img2, fit: [80, 80], style: 'choiceImage' }
        ]
      })
    } else if (img1 !== null && img2 === null) {
      doc.push({
        columns: [
          { image: img1, fit: [80, 80], style: 'choiceImage' },
          { text: '' }
        ]
      })
    } else if (img1 === null && img2 !== null) {
      doc.push({
        columns: [
          { text: '' },
          { image: img2, fit: [80, 80], style: 'choiceImage' },
        ]
      })
    }

    if (option3 !== undefined && option4 !== undefined) {
      doc.push({
        columns: [
          { text: '3. ' + ((option3?.name === '.') ? ' ' : option3?.name || ''), style: 'choice' },
          { text: '4. ' + ((option4?.name === '.') ? ' ' : option4?.name || ''), style: 'choice' }
        ]
      })
    } else if (option4 === undefined) {
      doc.push({
        columns: [
          { text: '3. ' + ((option3?.name === '.') ? ' ' : option3?.name || ''), style: 'choice' }
        ]
      })
    }

    if (img3 !== null && img4 !== null) {
      doc.push({
        columns: [
          { image: img3, fit: [80, 80], style: 'choiceImage' },
          { image: img4, fit: [80, 80], style: 'choiceImage'}
        ]
      })
    } else if (img3 !== null && img4 === null) {
      doc.push({
        columns: [
          { image: img3, fit: [80, 80], style: 'choiceImage'},
          { text: '' }
        ]
      })
    } else if (img3 === null && img4 !== null) {
      doc.push({
        columns: [
          { text: '' },
          { image: img4, fit: [80, 80], style: 'choiceImage'},
        ]
      })
    }
    doc.push('\n')
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [ 40, 45, 40, 65 ],
    content: [
      {
        text: 'ชุดข้อสอบ ' + data.exam_name,
        style: 'header',
        alignment: 'center'
      },
      { text: 'วิชา: ' + data.subject },
      { text: 'คอร์ส: ' + data.course_name },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, y1: 10,
            x2: 515, y2: 10,
            lineWidth: 0.1
          },
        ]
      }
    ].concat(doc),
    styles: {
      header: {
        fontSize: 16,
        bold: true
      },
      choice: {
        margin: [10, 0, 0, 0],
        alignment: 'left'
      },
      choiceImage: {
        margin: [10, 0],
        alignment: 'center'
      }
    },
    defaultStyle: {
      fontSize: 14,
      font: 'THSarabunNew'
    }
  }
  pdfMake.createPdf(docDefinition).open()
  // pdfMake.createPdf(docDefinition).download('document.pdf')
}
export const pdfAnswer = async (data) => {
  let doc = []
  doc.push('\n')
  for (let i = 0; i < data.questions.length; i++) {
    const questions = data.questions[i]
    doc.push(i + 1 + '. ' + questions.name.replace(/\n/g, "").replace(/\r/g, ""))

    if (questions?.image !== undefined && questions?.image !== null && questions?.image !== '' && questions?.image !== 'null') {
      doc.push({
        image: await getBase64ImageFromURL(pathUrl + questions?.image),
        alignment: 'center',
        maxWidth: 250,
        maxHeight: 180
      })
    }

    const result = questions.options.filter(x => parseInt(x.id) === parseInt(questions.answer_id))[0]
    doc.push({
      text: 'เฉลย: ' + questions.answer_id + '. ' + ((result?.name === '.') ? '' : result?.name),
      style: 'choice'
    })

    if (questions.answer_description !== undefined && questions.answer_description !== null && questions.answer_description !== '') {
      doc.push({
        text: 'คำอธิบาย: ' + questions.answer_description || '',
        style: 'choice'
      })
    }

    if (questions.answer_img !== null && questions.answer_img !== '' && questions.answer_img !== undefined && questions.answer_img !== 'null') {
      doc.push({
        image: await getBase64ImageFromURL(pathUrl + questions.answer_img),
        maxWidth: 250,
        maxHeight: 180,
        style: 'choiceImage'
      })
    }

    doc.push('\n')
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [ 40, 45, 40, 65 ],
    content: [
      {
        text: 'เฉลยชุดข้อสอบ ' + data.exam_name,
        style: 'header',
        alignment: 'center'
      },
      { text: 'วิชา: ' + data.subject },
      { text: 'คอร์ส: ' + data.course_name },
      {
        canvas: [
          {
            type: 'line',
            x1: 0, y1: 10,
            x2: 515, y2: 10,
            lineWidth: 0.1
          },
        ]
      }
    ].concat(doc),
    styles: {
      header: {
        fontSize: 16,
        bold: true
      },
      choice: {
        margin: [10, 0]
      },
      choiceImage: {
        margin: [50, 0]
      }
    },
    defaultStyle: {
      fontSize: 14,
      font: 'THSarabunNew'
    }
  }
  pdfMake.createPdf(docDefinition).open()
  // pdfMake.createPdf(docDefinition).download('document.pdf')
}

