import { createSlice } from '@reduxjs/toolkit';
import api from './api';
import { ReducerExams } from './types';

export const GET_EXAMS = (params) => async (dispatch) => {
  const res = await api.getExams(params)
  dispatch({ type: ReducerExams.SET_EXAMS, data: res.data })
}

export const GET_EXAM_BY_ID = (id) => async (dispatch) => {
  const res = await api.getExamById(id)
  dispatch({ type: ReducerExams.SET_EXAM, data: res.data })
  return res.data
}

export const GET_EXAM_BY_COURSE = (id) => async (dispatch) => {
  const res = await api.getExamByCourse(id)
  dispatch({ type: ReducerExams.SET_EXAM, data: res.data })
  return res.data
}

export const CREATE_EXAM = (body) => async (dispatch) => {
  const res = await api.createExam(body)
  return res.data
}

export const UPDATE_EXAM = (id, body) => async (dispatch) => {
  const res = await api.updateExam(id, body)
  return res.data
}

export const DELETE_EXAM = (id) => async (dispatch) => {
  const res = await api.deleteExam(id)
  return res.data
}

const examsReducer = createSlice({
  name: 'exams',
  initialState: {
    lists: [],
    exam: {}
  },
  extraReducers: {
    [ReducerExams.SET_EXAMS]: (state, action) => {
      state.lists = action.data
    },
    [ReducerExams.SET_EXAM]: (state, action) => {
      state.exam = action.data
    }
  },
})

const { reducer } = examsReducer
export default reducer
