// Chakra imports

import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel, Divider, Flex,
  Image, Spacer, Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET_QUESTION_BY_EXAM } from "store/questions";

function ResultExam() {
  const location = useLocation();
  const {examname, questionlength, score, exam_id, wrongAnswers, history_duration} = location.state
  const [questionTest, setQuestionTest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bgColor = useColorModeValue("white", "gray.700");
  const history = useHistory();
  const pathUrl = process.env.REACT_APP_UPLOAD_PATH
  let durationMinutes = parseInt(history_duration/60)
  let durationSeconds = history_duration%60

  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_QUESTION_BY_EXAM(exam_id))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])
  
  let questionList = useSelector((state) => state.questions.question)

  useEffect(() => {
    if (questionList && Object.keys(questionList).length !== 0) {
      setIsLoading(false)
      setQuestionTest(questionList?.questions)
    }
  }, [questionList]);

  const percentScore = ((score/questionlength)*100).toFixed(2)
  let colorPercent = 'red.400'
  if (percentScore > 80) { colorPercent = 'green.400' }
  else if (percentScore > 60) { colorPercent = 'yellow.400' }
  else if (percentScore > 40) { colorPercent = 'orange.400' }

  

  // return null

  if(questionTest && questionTest.length > 0 && !isLoading) {
    return (
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt={{ base: "1rem", md: "3rem" }}
          mb='1rem'>
          <Text fontSize='1.5rem' color='white' fontWeight='bold'>
            ผลการทำข้อสอบ
          </Text>
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='800px'
            background='transparent'
            borderRadius='15px'
            p={{ base: "1rem", md: "2rem" }}
            mx={{ base: "1rem" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
              <Flex direction={{base:'column', md: 'row'}} alignItems='center'>
                <Box p='0.5rem'>
                  <Text fontSize='1rem' fontWeight='bold' mb='1rem'>ชื่อข้อสอบ {examname}</Text>
                  <Text fontSize='1rem' fontWeight='bold' mb='1rem'>คะแนนที่ได้ {score} / {questionlength} </Text>
                  <Text fontSize='1rem' fontWeight='bold' mb='1rem'>ใช้เวลาทั้งหมด {durationMinutes != 0 ? `${durationMinutes} นาที ` : ''} {durationSeconds != 0 ? `${durationSeconds} วินาที ` : ''}</Text>
                </Box>
                <Spacer />
                <Box p='0.5rem'>
                  <CircularProgress value={percentScore} color={colorPercent} size={{ base: "100px", md: "120px" }} mb='1rem'>
                    <CircularProgressLabel fontSize='1rem'>{percentScore}%</CircularProgressLabel>
                  </CircularProgress>
                </Box>
              </Flex>
                  <h2>
                    <Box flex='1' textAlign='left' color='white' bgColor='teal' p={1}>
                      เฉลยข้อสอบ
                    </Box>
                  </h2>
                    {questionTest.map((item, id)=>{
                      let options_ans = item.options.filter(x => parseInt(x.id) === parseInt(item.answer_id))[0]
                      return (
                        <Box key={id} my='1rem' color={wrongAnswers.includes(item.id) ? useColorModeValue("red.600", "red.300") : useColorModeValue("gray.700", "white")}>
                          <Text fontWeight='bold' >โจทย์ : {item.name}</Text>
                          {(item.image !== undefined && item.image !== '' && item.image !== null && item.image !== 'null')
                            && <Image maxHeight='300px' m='auto' src={pathUrl + item.image} />}
                          <Text>คำตอบ : {options_ans.name}</Text>
                          {(options_ans.img !== undefined && options_ans.img !== '' && options_ans.img !== null && options_ans.img !== 'null')
                            && <Image maxHeight='300px' m='auto' src={pathUrl + options_ans.img} />}
                          <Text>อธิบายคำตอบ : {item.answer_description}</Text>
                          {(item.answer_img !== undefined && item.answer_img !== '' && item.answer_img !== null && item.answer_img !== 'null')
                            && <Image maxHeight='300px' m='auto' src={pathUrl + item.answer_img} />}
                          <Divider pt={4} borderColor="gray.400" />
                        </Box>
                      )
                    })}
              <Button
                colorScheme='teal' variant='outline'
                minW='110px'
                h='36px'
                fontSize='xs'
                px='1.5rem'
                fontSize='1rem'
                color='teal'
                fontWeight='bold'
                w='100%'
                h='45'
                my='24px'
                _hover={{
                    bg: "teal.400",
                    color: "white"
                }}
                _active={{
                    bg: "teal.400",
                }}
                onClick={() => history.goBack()}>
                กลับหน้าหลัก
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  return null;
}

export default ResultExam;
