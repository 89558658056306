import {
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom'

function TablesTableRow(props) {
  const { id, name, description, subject, className, quantity, status } = props
  const textColor = useColorModeValue('gray.700', 'white')
  const history = useHistory()
  const routeEdit = () =>{
    let path = `/admin/courses/edit/${id}`
    history.push(path)
  }

  return (
    <Tr>

      <Td pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td maxWidth='400px'>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} noOfLines={2}>
            {description}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor}>
            {subject}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem">
          {className}
        </Text>
      </Td>

      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem">
          {quantity}
        </Text>
      </Td>

      <Td>
        <Badge
          bg={status ? "green.400" : 'gray.400'}
          color="white"
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
        </Badge>
      </Td>

      <Td>
        <Button p="0px" bg="transparent" variant="no-hover" onClick={routeEdit}>
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            แก้ไข
          </Text>
        </Button>
      </Td>
    </Tr>
  )
}

export default TablesTableRow
