import { configureStore } from '@reduxjs/toolkit'
import coursesReducer from './courses'
import examsReducer from './exams'
import questionsReducer from './questions'
import UsersReducer from './users'

const reducer = {
  exams: examsReducer,
  courses: coursesReducer,
  questions: questionsReducer,
  users: UsersReducer
}

export const store = configureStore({
  reducer: reducer
})
