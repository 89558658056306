// Chakra imports
import {
  Box, Button, Flex, FormControl,
  FormLabel, Image, Input, Radio, RadioGroup,
  Stack, Text, Textarea, useColorModeValue
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_QUESTION } from "store/questions";
import * as Yup from 'yup';

function AddQuestion() {
  const textColor = useColorModeValue("gray.700", "white");
  const [value, setValue] = useState('2')
  const dispatch = useDispatch()
  const { id } = useParams()

  const validationSchema = Yup.object({
    question: Yup.string().required('บังคับกรอก')
  })

  const history = useHistory()
  const [imgQuestion, setImgQuestion] = useState({})
  const [imgOption1, setImgOption1] = useState({})
  const [imgOption2, setImgOption2] = useState({})
  const [imgOption3, setImgOption3] = useState({})
  const [imgOption4, setImgOption4] = useState({})
  const [imgAnswer, setImgAnswer] = useState({})
  const [imgOptions, setImgOptions] = useState([])

  const formik = useFormik({
    initialValues: {
      answer_id: '1'
    },
    onSubmit: (values, { setSubmitting }) => {
      let options = []
      Object.entries(values).map(([key, value]) => {
        if(key.startsWith('option')) {
          let id = key.split('-')[1]
          let obj = {
            "id": parseInt(id),
            "name": value.replaceAll("'","’")
          }
          options.push(obj)
        }
      })
      const dataForm = new FormData()
      dataForm.append('exam_id', parseInt(id))
      dataForm.append('question', values.question.replaceAll("'","’"))
      dataForm.append('options', JSON.stringify(options))
      dataForm.append('answer_id', parseInt(values.answer_id))
      dataForm.append('answer_desc', values.answer_desc.replaceAll("'","’"))

      if (imgQuestion.file) dataForm.append('files', imgQuestion.file)
      if (imgOption1.file) dataForm.append('files', imgOption1.file)
      if (imgOption2.file) dataForm.append('files', imgOption2.file)
      if (imgOption3.file) dataForm.append('files', imgOption3.file)
      if (imgOption4.file) dataForm.append('files', imgOption4.file)
      if (imgAnswer.file) dataForm.append('files', imgAnswer.file)

      dispatch(CREATE_QUESTION(dataForm))
      .then(data => {
        setSubmitting(false)
        history.push(`/admin/exams/${id}/question/`)
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  })

  const onFileChangeQuestion = (event) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const myNewFile = new File([file], 'question', { type: file.type })
      const reader = new FileReader()
      reader.onload = (e) => {
        setImgQuestion({ file: myNewFile, base64: e.target.result })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const onFileChangeOptions = (event, index) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const myNewFile = new File([file], 'opt' + (index + 1), { type: file.type })
      const reader = new FileReader()
      reader.onload = (e) => {
        imgOptions[index] = { index: index, base64: e.target.result }
        switch (index + 1) {
          case 1:
            setImgOption1({ file: myNewFile, base64: e.target.result })
            break
          case 2:
            setImgOption2({ file: myNewFile, base64: e.target.result })
            break
          case 3:
            setImgOption3({ file: myNewFile, base64: e.target.result })
            break
          default:
            setImgOption4({ file: myNewFile, base64: e.target.result })
        }
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const onFileChangeAnswer = (event) => {
    if (event.target?.files[0]) {
      const file = event.target.files[0]
      const myNewFile = new File([file], 'answer', { type: file.type })
      // setImgAnswer(myNewFile)
      const reader = new FileReader()
      reader.onload = (e) => {
        setImgAnswer({ file: myNewFile, base64: e.target.result })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const removeQuestion = () => {
    setImgQuestion({})
  }

  const removeOptions = (index) => {
    imgOptions[index] = {}
    switch (index + 1) {
      case 1:
        setImgOption1({})
        break
      case 2:
        setImgOption2({})
        break
      case 3:
        setImgOption3({})
        break
      default:
        setImgOption4({})
    }
  }

  const removeAnswer = () => {
    setImgAnswer({})
  }

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              เพิ่มคำถาม
            </Text>
          </CardHeader>
          <CardBody p="20px">
            <FormControl>
              <FormLabel ms='4px' fontWeight='semibold'>
                คำถาม
              </FormLabel>
              <Flex gap='5'>
                <Box flex='5'>
                  <Textarea
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    mb='10px'
                    size='lg'
                    name="question"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.question || ''}
                  />
                  <Text style={{position: 'relative'}}>
                    <Button mb='24px' colorscheme='teal' variant='outline'>
                      <Text fontSize="sm" fontWeight="semibold">
                        ใส่รูปภาพ 
                      </Text>
                    </Button>
                    <input type='file' id='file' 
                      onChange={onFileChangeQuestion}
                      accept='image/jpg, image/png'
                      style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}} 
                    />
                    {imgQuestion.base64 &&
                      <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={removeQuestion}>
                        <Text fontSize="sm" fontWeight="semibold" color="red.500">
                          ลบรูปภาพ
                        </Text>
                      </Button>
                    }
                  </Text>
                </Box>
                <Box flex='2'>
                  {imgQuestion.base64 &&
                    <Box mb='20px' position='relative' borderRadius='15px' width='40%'>
                      <Image maxHeight='200px' src={imgQuestion.base64} borderRadius='15px' />
                      <Box
                        w='100%'
                        h='100%'
                        position='absolute'
                        top='0'
                        borderRadius='15px'></Box>
                    </Box>
                  }
                </Box>
              </Flex>
              <FormLabel ms='4px' fontWeight='semibold'>
                จำนวนตัวเลือก
              </FormLabel>
              <RadioGroup
                mb='24px' size='md'
                onChange={setValue} 
                value={value}>
                <Stack spacing={5} direction='row' mb='24px'>
                  <Radio value='2'>2</Radio>
                  <Radio value='3'>3</Radio>
                  <Radio value='4'>4</Radio>
                </Stack>
              </RadioGroup>
            
              {Array(parseInt(value)).fill(1).map((el, i) =>
                <Flex gap='5' key={i}>
                  <Box flex='1'>
                    <FormLabel ms='4px' fontWeight='semibold'>
                      ตัวเลือกที่ {i+1}
                    </FormLabel>
                  </Box>
                  <Box flex='5'>
                    <Input
                      fontSize='sm'
                      ms='4px'
                      borderRadius='15px'
                      type='text'
                      mb='24px'
                      size='lg'
                      mr='20px'
                      name={`option-${i+1}`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                      {imgOptions[i]?.base64 &&
                      <Box mb='20px' position='relative' borderRadius='15px' width='40%'>
                        <Image maxHeight='200px' src={imgOptions[i].base64} borderRadius='15px' />
                        <Box
                          w='100%'
                          h='100%'
                          position='absolute'
                          top='0'
                          borderRadius='15px'></Box>
                      </Box>
                      }
                    </Box>
                    <Box flex='2'>
                      <Text style={{position: 'relative'}}>
                        <Button mb='24px' colorscheme='teal' variant='outline'>
                          <Text fontSize="sm" fontWeight="semibold">
                            ใส่รูปภาพ
                          </Text>
                        </Button>
                        <input type='file' id='file' 
                          onChange={(e) => onFileChangeOptions(e, i)}
                          accept='image/jpg, image/png'
                          style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}} 
                          />
                          {imgOptions[i]?.base64 &&
                            <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={() => removeOptions(i)}>
                              <Text fontSize="sm" fontWeight="semibold" color="red.500">
                                ลบรูปภาพ
                              </Text>
                            </Button>
                          }
                      </Text>
                    </Box>
                    
                </Flex>
              )}

              <FormLabel ms='4px' fontWeight='semibold'>
                คำตอบที่ถูกต้อง
              </FormLabel>
              <RadioGroup mb='24px' size='md'
                onChange={selectedOption =>
                  formik.setFieldValue("answer_id", selectedOption)
                } 
                value={formik.values.answer_id || ''} >
                <Stack spacing={5} direction='row' mb='24px'>
                  {Array(parseInt(value)).fill(1).map((el, i) =>
                    <Radio key={i} value={`${i+1}`}>ตัวเลือกที่ {i+1}</Radio>
                  )}
                </Stack>
              </RadioGroup>
              <FormLabel ms="4px" fontWeight='semibold'>
                รายละเอียดคำตอบ
              </FormLabel>
              <Flex gap='5'>
                <Box flex='5'>
                  <Textarea
                    id="answer_desc"
                    name="answer_desc"
                    fontSize="sm"
                    ms="4px"
                    borderRadius="15px"
                    type="textarea"
                    mb="24px"
                    size="lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.answer_desc || ''}
                  />
                  <Text style={{position: 'relative'}}>
                    <Button mb='24px' colorscheme='teal' variant='outline'>
                      ใส่รูปภาพ 
                    </Button>
                    <input type='file' id='file' 
                      onChange={onFileChangeAnswer}
                      accept='image/jpg, image/png'
                      style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, width: '100px', height: '40px'}} 
                      />
                    {imgAnswer.base64 &&
                      <Button mb='24px' ml='16px' colorscheme='teal' variant='outline' onClick={removeAnswer}>
                        <Text fontSize="sm" fontWeight="semibold" color="red.500">
                          ลบรูปภาพ
                        </Text>
                      </Button>
                    }
                  </Text>
                </Box>
                <Box flex='2'>
                {imgAnswer.base64 &&
                  <Box mb='20px' position='relative' borderRadius='15px' width='40%'>
                    <Image maxHeight='200px' src={imgAnswer.base64} borderRadius='15px' />
                    <Box
                      w='100%'
                      h='100%'
                      position='absolute'
                      top='0'
                      borderRadius='15px'></Box>
                  </Box>
                }
                </Box>
              </Flex>

              <Flex>
                <Button
                  type='submit'
                  bg='teal.300'
                  fontSize='20px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  mr='10px'
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}>
                  สร้างคำถาม
                </Button>
              </Flex>
            </FormControl>
          </CardBody>
        </Card>
      </form>
    </Flex>
  );
}

export default AddQuestion;
