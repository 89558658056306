// Chakra imports

import { Flex, Image, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useStopwatch } from 'react-timer-hook';
import { GET_QUESTION_BY_EXAM } from "store/questions";
import { SEND_HISTORY } from "store/users";
import ButtonOption from "./components/ButtonOption";
import TimeCountdown from "./components/TimeCountdown";

function TakeExam() {
  const bgColor = useColorModeValue("white", "gray.700");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checkCurrentQuestion, setCheckCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [questionTest, setQuestionTest] = useState([]);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const time = new Date();
  const pathUrl = process.env.REACT_APP_UPLOAD_PATH

  const dispatch = useDispatch()
  const { course_id, id } = useParams()
  const initFetch = useCallback(() => {
    dispatch(GET_QUESTION_BY_EXAM(id))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  let shuffle = (arr = []) => {
    if(!arr)
      return []
    return [...arr].sort(() => Math.random() - 0.5)
  }
  let questionList = useSelector((state) => state.questions.question)
  time.setSeconds(time.getSeconds() + parseInt(questionList.exam_duration)); // 2 minutes timer

  useEffect(() => {
    if (questionList && Object.keys(questionList).length !== 0) {
      setIsLoading(false)
      setQuestionTest(shuffle(questionList?.questions))
      time.setSeconds(time.getSeconds() + parseInt(questionList.exam_duration)); // 2 minutes timer
    }
  }, [questionList]);

  const {
    seconds,
    minutes
  } = useStopwatch({ autoStart: true });

  const history = useHistory()
  const routeResult = () =>{
    let log = {
      user_id: Cookies.get('user_id'),
      course_id: course_id,
      exam_id: id,
      score: score,
      quantity: questionTest.length,
      duration: (minutes*60)+seconds,
      wrongAnswers: wrongAnswers
    }
    dispatch(SEND_HISTORY(log))
    let path = `/exam/resultexam`
    history.push({
      pathname: path,
      state: {
        examname: questionList.exam_name,
        score: score,
        questionlength: questionTest.length,
        questionTest: questionTest,
        durationMinutes: minutes,
        durationSeconds: seconds,
        wrongAnswers: wrongAnswers
      }
    })
  }

  const handleAnswerButtonClick = (isCorrect, question_id) => {
    if (isCorrect) {
      setScore(score + 1)
    } else {
      setWrongAnswers([...wrongAnswers, question_id]);
    }
    const nextQuestion = currentQuestion + 1
    const checkNextQuestion = checkCurrentQuestion + 1
    if (nextQuestion < questionTest.length) {
      setCurrentQuestion(nextQuestion)
    }
    if (checkNextQuestion <= questionTest.length) {
      setCheckCurrentQuestion(checkNextQuestion)
    }
  };

  useEffect(() => {
    if (questionTest && checkCurrentQuestion == questionTest.length && questionTest.length != 0) {
      routeResult()
    }
  }, [checkCurrentQuestion])

  if(questionTest && questionTest.length > 0 && !isLoading) {
    if(questionTest.length == 1 && questionTest[0].name == null) {
      return (
        <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='800px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "50px" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            ไม่มีข้อมูลข้อสอบ โปรดแจ้งคุณครู
          </Flex>
        </Flex>
      </Flex>

      )
    }
    return (
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt={{ base: "0.5rem", md: "3rem" }}
          mb='0.5rem'>
          <Text fontSize={{ base: "0.8rem", md: "1.2rem" }} color='white' fontWeight='bold'>
            ข้อสอบ {questionList.exam_name}
          </Text>
          <Text fontSize={{ base: "1.2rem", md: "1.7rem" }} color='white' fontWeight='bold'>
            ข้อที่ {currentQuestion + 1} / {questionTest.length}
          </Text>
          <TimeCountdown
            expiryTimestamp={time}
            checkCurrentQuestion={checkCurrentQuestion}
            handleAnswerButtonClick={handleAnswerButtonClick}
            question_id={questionTest[currentQuestion].id}
          />
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w={{ base: "100%", md: "75%" }}
            background='transparent'
            borderRadius='15px'
            p={{ base: "1rem", md: "2rem" }}
            mx={{ md: "1rem" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
              <Text fontSize={{ base: "1.2rem", md: "1.5rem" }} mb='1rem' fontWeight='semibold'>
                {questionTest[currentQuestion].name}
                {(questionTest[currentQuestion].image !== undefined && questionTest[currentQuestion].image !== null && questionTest[currentQuestion].image !== '' && questionTest[currentQuestion].image !== 'null')
                  && <Image maxHeight='300px' m='auto' src={pathUrl + questionTest[currentQuestion].image} />}
              </Text>
              <SimpleGrid minChildWidth={{ base: "100px", sm: "200px", md: "250px", lg: "350px", xl: "500px" }} spacing='10px'>
                {questionTest ? questionTest[currentQuestion].options.map((item) => {
                  return (
                    <ButtonOption
                      key={item.id}
                      item={item}
                      handleAnswerButtonClick={handleAnswerButtonClick}
                      answer_id={questionTest[currentQuestion].answer_id}
                      question_id={questionTest[currentQuestion].id}
                    />
                  )
                }) : null}

              </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    )
  }
  return null;
}

export default TakeExam;
