// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesHisoryExamRow from "components/Tables/TablesHisoryExamRow";
import React from "react";

const HistoryExam = ({ title, captions, data, maxHeight, role }) => {
  const textColor = useColorModeValue("gray.700", "white");
  if(Array.isArray(data) && (data.length == 0)) {
    return (
      <Card p='16px' my='24px'>
        <CardBody px='5px'>
          <div>ไม่มีข้อมูลการทำข้อสอบ</div>
        </CardBody>
      </Card>
    )
  }

  return (
   <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='12px 5px' mb='12px'>
          <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
            {title}
          </Text>
      </CardHeader>
      <CardBody px='5px' maxHeight={maxHeight} overflowY='scroll'>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((row) => {
              return (
                <TablesHisoryExamRow
                  key={row.id}
                  name={row.exam_name}
                  group={row.course_name}
                  date={row.timestamp}
                  score={row.score}
                  quantity={row.quantity}
                  percent={((row.score/row.quantity)*100).toFixed(2)}
                  role={role}
                  exam_id={row.exam_id}
                  wrongAnswers={row.wrongAnswers}
                  history_duration={row.history_duration}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default HistoryExam;
