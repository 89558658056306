import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { SearchBar } from "components/Navbars/SearchBar/SearchBar"
import CoursesTableRow from 'components/Tables/CoursesTableRow'
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { GET_COURSES } from 'store/courses'

const TableData = ({ title, captions, data }) => {
  const textColor = useColorModeValue('gray.700', 'white')
  const bgButton = useColorModeValue(
    'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)',
    'gray.800'
  )
  const history = useHistory()
  const routeAdd = () =>{ 
    let path = `/admin/courses/add`
    history.push(path)
  }
  const dispatch = useDispatch()
  
  const fetchData = useCallback((searchInput) => {
    dispatch(GET_COURSES(`?search=${searchInput}`))
  }, [dispatch])

  return (
    <Card overflowX={{ sm: 'scroll', xl: 'hidden' }}>
      <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <SearchBar fetchData={fetchData} />
          <Button bg={bgButton} color='white' fontSize='xs' variant='no-hover' onClick={routeAdd}>
            เพิ่มคอร์สเรียน
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <CoursesTableRow
                  key={`$${row.name}`}
                  id={row.id}
                  name={row.name}
                  description={row.description}
                  subject={row.subject}
                  className={row.class}
                  quantity={row.quantity}
                  status={row.status}
                />
              )
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default TableData
