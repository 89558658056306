// Chakra Icons
// Chakra Imports
import {
  Button,
  Flex, Text, useColorModeValue
} from "@chakra-ui/react";
import AuthApi from "AuthApi";
// Custom Icons
import { SettingsIcon } from "components/Icons/Icons";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { FiLogOut } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import routes from "routes.js";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  // Chakra Color Mode
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  const Auth = useContext(AuthApi)

  const handleOnClick = () => {
    Auth.setAuth(false)
    Cookies.remove("role")
    // document.dispatchEvent(new Event('logout'))
  }

  if (secondary) {
    navbarIcon = "gray.200";
    mainText = "gray.200";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <NavLink to="/login">
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          onClick={handleOnClick}
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <FiLogOut color={navbarIcon} w="22px" h="22px" me="0px" />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <FiLogOut color={navbarIcon} w="22px" h="22px" me="0px" />
            ) : (
              ""
            )
          }
        >
          <Text display={{ sm: "none", md: "flex" }}>ออกจากระบบ</Text>
        </Button>
      </NavLink>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />
      <SettingsIcon
        display={{ sm: "none", xl: "flex" }}
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
