import AuthApi from "AuthApi";
import Cookies from "js-cookie";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import ExamLayout from "layouts/Exam";
import HomeLayout from "layouts/Home";
import UserLayout from "layouts/User";
import React, { createElement, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { store } from '../src/store/index';


function App() {
  const [auth,setAuth] = useState(false)
  const [rolePage,setRolePage] = useState(null)
  const readCookie = () => {
    const role = Cookies.get("role");
    if (role) {
      setAuth(true)
      setRolePage(role)
    }
  }

  useEffect(() => {
    readCookie()
  }, [rolePage])

  return (
    <div>
      <AuthApi.Provider value={{auth,setAuth,rolePage,setRolePage}}>
        <BrowserRouter>
          <Routes setRolePage={setRolePage} />
        </BrowserRouter>
      </AuthApi.Provider>
    </div>
  )
}

const ProtectedRoute = ({auth, component:Component, ...rest}) => {
  return(
    <Route
    {...rest}
    render = {() => auth ? (
      <Component />
    ): <Redirect to="/login"/>
  }
    />
  )
}

const ProtectedLogin = ({auth, rolePage, setRolePage, component:Component, ...rest}) => {
  let adminVar = {
    pathname: "/admin/users",
    setRolePage : setRolePage
  }
  let userVar = {
    pathname: "/user/profile",
    setRolePage : setRolePage
  }
  return(
    <Route
    {...rest}
    render = {() => !auth ? (
      <Component />
    ): rolePage == 'Admin' ? <Redirect to={adminVar}/> : rolePage == 'User' ? <Redirect to={userVar}/> : <div />
  }
    />
  )
}

const Routes = ({setRolePage}) => {
  const Auth = useContext(AuthApi)
  return(
    <Provider store={store}>
      <Switch>
        <Route path={`/signup`} component={AuthLayout} />
        <ProtectedRoute path={`/admin`} auth={Auth.auth} component={AdminLayout} />
        <ProtectedRoute path={`/exam`} auth={Auth.auth} component={ExamLayout} />
        <ProtectedRoute path={`/user`} auth={Auth.auth} component={UserLayout} />
        <ProtectedLogin path={`/login`} auth={Auth.auth} rolePage={Auth.rolePage} setRolePage={setRolePage} component={AuthLayout} />
        <Route path={`/`} component={HomeLayout} />
      </Switch>
    </Provider>
  )
}

ReactDOM.render(createElement(App), document.getElementById("root"));
