import { Flex } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_COURSES } from 'store/courses'
import TableData from './components/TableData'

function Tables() {
  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_COURSES(''))
  }, [dispatch])
  useEffect(() => {
    initFetch()
  }, [initFetch])
  const lists = useSelector((state) => state.courses.lists)
  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <TableData
        title={'รายชื่อคอร์สเรียน'}
        captions={['ชื่อคอร์สเรียน', 'รายละเอียด', 'วิชา', 'ชั้นปีการศึกษา', 'จำนวนข้อสอบ', 'สถานะ', '', '']}
        data={lists}
      />
    </Flex>
  );
}

export default Tables;
