// Chakra imports
import {
  Box,
  Button,
  Flex, FormLabel, Input,
  Link, SimpleGrid, Text,
  useColorModeValue
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import { useFormik } from 'formik';
import React from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { REGISTER } from "store/users";
import { CLASS } from "variables/enumObject";
import * as Yup from 'yup';

function SignUp() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
  const bgColor = useColorModeValue('#FFF','#718096');
  const hoverColor = useColorModeValue('#CBD5E0','#4A5568');
  const selectColor = useColorModeValue('#718096','#1A202C');
  const dispatch = useDispatch()
  const history = useHistory();
  const routeLogin = () =>{ 
    let path = `/login`; 
    history.push(path);
  }
  const customStyles = {
    control: (styles, isInvalid) => ({
      ...styles,
      borderRadius: '15px',
      outline: '2px solid transparent',
      borderColor: isInvalid ? '#E2E8F0' : '#E53E3E',
      height: '3rem',
      marginBottom: '5px'
    }),
    option: (styles, state) => ({
      ...styles,      
      backgroundColor: state.isFocused ? hoverColor : state.isSelected ? selectColor : bgColor,
    }), 
  }

  const validationSchema = Yup.object({
    firstname: Yup.string().required('บังคับกรอก'),
    lastname: Yup.string().required('บังคับกรอก'),
    username: Yup.string().required('บังคับกรอก'),
    password: Yup.string().min(2, 'สั้นไป!').max(20, 'ยาวไป!').required('บังคับกรอก'),
    class: Yup.string().required('บังคับกรอก'),
    school: Yup.string().required('บังคับกรอก')
  })

  const formik = useFormik({
    initialValues: {
      role: 'User',
      status: 'Active'
      // status: 'Pending Approval'
    },
    onSubmit: (values, { setSubmitting }) => {
      dispatch(REGISTER(
        {
          firstname: values.firstname,
          lastname: values.lastname,
          username: values.username,
          password: values.password,
          class: CLASS.filter(x => x.value === values.class)[0].label,
          school: values.school,
          email: values.email,
          mobile: values.mobile,
          status: 'Active'
        }
      ))
      .then(data => {
        setSubmitting(false)
        history.push('/admin/users')
      })
      .catch(e => {
        setSubmitting(true)
      })
    },
    validationSchema
  });

  console.log('formik', formik.errors)

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "40vh", md: "30vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgImage={BgSignUp}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex
        direction='column'
        textAlign='center'
        justifyContent='center'
        align='center'
        mt='3rem'
        mb='1rem'>
        <Text fontSize='2rem' color='white' fontWeight='bold'>
          สมัครเข้าใช้งานระบบ
        </Text>
        <Text fontSize='2rem' color='white' fontWeight='bold'>
          KID D EXAMINATION
        </Text>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='800px'
          background='transparent'
          borderRadius='15px'
          p='2rem'
          mx={{ base: "50px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            <form onSubmit={formik.handleSubmit}>
              <SimpleGrid minChildWidth='200px' spacing='10px'>
                <Box flex='1' mb='1rem'>
                  <FormLabel ms='4px' fontWeight='semibold'>
                    ชื่อจริง
                  </FormLabel>
                  <Input
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    mb='5px'
                    name="firstname" 
                    size='lg'
                    isInvalid={(formik.touched.firstname && formik.errors.firstname) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname || ''}
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <Text color='tomato' fontSize='sm' fontSize='sm'>{formik.errors.firstname}</Text>
                  )}
                </Box>
                <Box flex='1' mb='1rem'>
                  <FormLabel ms='4px' fontWeight='semibold'>
                    นามสกุล
                  </FormLabel>
                  <Input
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    name="lastname"
                    mb='5px'
                    size='lg'
                    isInvalid={(formik.touched.lastname && formik.errors.lastname) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname || ''}
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <Text color='tomato' fontSize='sm' fontSize='sm'>{formik.errors.lastname}</Text>
                  )}
                </Box>
              </SimpleGrid>
              <SimpleGrid minChildWidth='200px' spacing='10px'>
                <Box flex='1' mb='1rem'>
                  <FormLabel ms='4px' fontWeight='semibold'>
                    ชื่อผู้ใช้งาน
                  </FormLabel>
                  <Input
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    name="username"
                    mb='5px'
                    size='lg'
                    isInvalid={(formik.touched.username && formik.errors.username) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username || ''}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <Text color='tomato' fontSize='sm'>{formik.errors.username}</Text>
                  )}
                </Box>
                <Box flex='1' mb='1rem'>
                  <FormLabel ms='4px' fontWeight='semibold'>
                    รหัสผ่าน
                  </FormLabel>
                  <Input
                    ms='4px'
                    type="password"
                    name="password"
                    borderRadius='15px'
                    mb='5px'
                    size='lg'
                    isInvalid={(formik.touched.password && formik.errors.password) ? true : false }
                    errorBorderColor='red.300'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ''}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Text color='tomato' fontSize='sm'>{formik.errors.password}</Text>
                  )}
                </Box>
              </SimpleGrid>
              <Box flex='1' mb='1rem'>
                <FormLabel ms='4px' fontWeight='semibold'>
                  ชั้นเรียน
                </FormLabel>
                <Select 
                  options={CLASS}
                  id='class'
                  name="class"
                  placeholder='เลือกชั้นเรียน' 
                  styles={customStyles}
                  isInvalid={(formik.touched.class && formik.errors.class) ? true : false }
                  onChange={selectedOption =>
                    formik.setFieldValue('class', selectedOption.value)
                  }
                  onBlur={formik.handleBlur} />
                  {formik.touched.class && formik.errors.class && (
                    <Text color='tomato' fontSize='sm'>{formik.errors.class}</Text>
                  )}
              </Box>
              <Box flex='1' mb='1rem'>
                <FormLabel ms='4px' fontWeight='semibold' >
                  โรงเรียน
                </FormLabel>
                <Input
                  ms='4px'
                  borderRadius='15px'
                  type='text'
                  name="school"
                  mb='5px'
                  size='lg'
                  isInvalid={(formik.touched.school && formik.errors.school) ? true : false }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.school || ''}
                />
                {formik.touched.school && formik.errors.school && (
                  <Text color='tomato' fontSize='sm'>{formik.errors.school}</Text>
                )}
              </Box>
              <Box flex='1' mb='1rem'>
                <FormLabel ms='4px' fontWeight='semibold'>
                  อีเมล
                </FormLabel>
                <Input
                  ms='4px'
                  borderRadius='15px'
                  mb='5px'
                  type="email"
                  name="email"
                  size='lg'
                  isInvalid={(formik.touched.email && formik.errors.email) ? true : false }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email || ''}
                />
                {formik.touched.email && formik.errors.email && (
                  <Text color='tomato' fontSize='sm'>{formik.errors.email}</Text>
                )}
              </Box>
              <Box flex='1' mb='1rem'>           
                <FormLabel ms='4px' fontWeight='semibold' >
                  เบอร์โทรศัพท์ (ผู้ปกครอง)
                </FormLabel>
                <Input
                  ms='4px'
                  borderRadius='15px'
                  type='text'
                  name="mobile"
                  mb='5px'
                  size='lg'
                  isInvalid={(formik.touched.mobile && formik.errors.mobile) ? true : false }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile || ''}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <Text color='tomato' fontSize='sm'>{formik.errors.mobile}</Text>
                )}
              </Box> 
              <Button
                type='submit'
                bg='teal.300'
                fontSize='1.2rem'
                color='white'
                fontWeight='bold'
                disabled={Object.keys(formik.errors).length != 0 ? true : false}
                w='100%'
                h='45'
                mb='24px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                สมัครสมาชิก
              </Button>
            </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            maxW='100%'
            mt='0px'>
            <Text color={textColor} fontWeight='medium'>
              มีบัญชีผู้ใช้งานแล้ว?
              <Link
                color={titleColor}
                as='span'
                ms='5px'
                fontWeight='bold'
                onClick={routeLogin}>
                เข้าสู่ระบบ
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
