// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import Exams from "./components/Exams";


function MyCourse() {

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Exams title={"คอร์สเรียนของฉัน"} description={""} />
    </Flex>
  );
}

export default MyCourse;
