import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GET_QUESTION_BY_EXAM } from "../../store/questions";
import { pdfAnswer, pdfQuestions } from "../../utils/pdfmake";

function CourseDetailTableRow(props) {
  const { name, quantity, id, course_id, page, print } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [loadingAnswers, setLoadingAnswers] = useState(false)
  const history = useHistory();
  const dispatch = useDispatch()
  const routeTakeExam = () =>{
    let path = `/exam/takeexam/${course_id}/${id}`;
    history.push(path);
  }
  let isMyCourse = (page == 'mycourse' || page == 'myfreecourse') ? true : false

  const printQuestion = async () => {
    setLoadingQuestions(true)
    let res = await dispatch(GET_QUESTION_BY_EXAM(id))
    await pdfQuestions(res)
    setLoadingQuestions(false)
  }

  const printAnswer = async () => {
    setLoadingAnswers(true)
    const res = await dispatch(GET_QUESTION_BY_EXAM(id))
    await pdfAnswer(res)
    setLoadingAnswers(false)
  }

  return (
    <Tr>
      <Td minWidth={{ md: "300px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
          </Flex>
        </Flex>
      </Td>
      {/* <Td>
        <Badge
          bg={status === "เปิดใช้งาน" ? "green.400" : bgStatus}
          color={status === "เปิดใช้งาน" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
      </Td> */}

      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem">
          {quantity}
        </Text>
      </Td>
      {isMyCourse &&
        <Td>
          <Button colorScheme='teal' borderRadius='5px' onClick={routeTakeExam} >
            <Text
              fontSize="md"
              color="white"
              fontWeight="bold"
              cursor="pointer"
            >
              เข้าทำข้อสอบ
            </Text>
          </Button>
        </Td>
      }
      {isMyCourse && print === 'true' &&
        <Td textAlign='center'>
          <Button
            colorScheme='gray'
            onClick={printQuestion}
            mr='5px'
            isLoading={loadingQuestions}
            loadingText='คำถาม'
          >
            คำถาม
          </Button>
          <Button
            colorScheme='gray'
            onClick={printAnswer}
            isLoading={loadingAnswers}
            loadingText='เฉลย'
          >
            เฉลย
          </Button>
        </Td>
      }
    </Tr>
  );
}

export default CourseDetailTableRow;
