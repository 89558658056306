// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import UsersTableRow from "components/Tables/UsersTableRow";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { GET_USERS } from "store/users";

const Authors = ({ title, captions, data, meta, listCourse }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const dispatch = useDispatch()

  const fetchData = useCallback((searchInput) => {
    dispatch(GET_USERS(`?search=${searchInput}`))
  }, [dispatch])

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <SearchBar fetchData={fetchData} />
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <UsersTableRow
                  key={row.id}
                  id={row.id}
                  name={`${row.firstname || ''} ${row.lastname || ''}`}
                  logo={row.logo}
                  username={row.username}
                  classroom={row.class}
                  school={row.school}
                  status={row.status}
                  print={row.print_status}
                  date={row.created_timestamp}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Authors;
