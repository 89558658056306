// Chakra imports
import {
  Box, Button,
  Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue, useDisclosure
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import QuestionsTableRow from "components/Tables/QuestionsTableRow";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_QUESTIONS_JSON_EXCEL } from "store/questions";
import * as XLSX from 'xlsx';

const Authors = ({ title, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  const dispatch = useDispatch()
  const history = useHistory();
  const { id } = useParams()
  const [jsonData, setJsonData] = useState()
  const [fileName, setFileName] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const routeAdd = () =>{
    let path = `/admin/exams/${id}/question/add`;
    history.push(path);
  }

  const inputFile = useRef(null)
  const onFileChange = (event) => {
    event.preventDefault()
    if (event.target.files) {
        const reader = new FileReader()
        setFileName(event.target.files[0].name)
        const fileNameString = event.target.files[0].name
        reader.onload = (e) => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: "binary" })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const json = XLSX.utils.sheet_to_json(worksheet, { raw: false })
          setJsonData({ exam_id: id, questions: json, filename: fileNameString })
          onOpen()
        }
        reader.readAsBinaryString(event.target.files[0])
    }
  }

  const importJson = () => {
    dispatch(CREATE_QUESTIONS_JSON_EXCEL(jsonData))
    .then(() => {
      onClose()
      history.push(`/admin/exams/${id}/question/`)
    })
    .catch(e => {
      console.log(e)
      onClose()
    })
  }

  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader>
        <Flex justify='space-between' align='center' minHeight='60px' w='100%'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title}
          </Text>
          <Text style={{position: 'relative'}}>
            <Button bg={bgButton} color='white' fontSize='xs' mr='2' variant='no-hover'>
              นำเข้าไฟล์ Excel
            </Button>
            <input type='file' id='file'
              name='question-pic'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              onChange={onFileChange}
              ref={inputFile}
              style={{overflow: 'hidden', position: 'absolute', left: 0, opacity: 0, height: '100%'}} />
            <Button bg={bgButton} color='white' fontSize='xs' variant='no-hover' onClick={routeAdd}>
            เพิ่มคำถาม
            </Button>
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data ? data.map((row, index) => {
              return (
                <QuestionsTableRow
                  key={`$${row.id}`}
                  questionId={row.id}
                  index={index+1}
                  question={row.name}
                />
              );
            }): null}
          </Tbody>
        </Table>
      </CardBody>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box w="100%" align='center'>
              ต้องการนำเข้าไฟล์ {fileName}
            </Box>
          </ModalHeader>
          <ModalFooter>
            <Box w="100%" align='center'>
              <Button colorScheme="teal" onClick={importJson}>
                นำเข้าไฟล์
              </Button>
              <Button ml="15px" colorScheme="teal" variant='outline' onClick={onClose}>
                ยกเลิก
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Authors;
