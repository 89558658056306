// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_COURSES } from 'store/courses';
import { GET_USERS } from "store/users";
import Authors from "./components/Authors";

function Tables() {
  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_USERS(''))
    dispatch(GET_COURSES(''))
  }, [dispatch])

  useEffect(() => {
    initFetch()
  }, [initFetch])

  const lists = useSelector((state) => state.users.lists)
  const meta = useSelector((state) => state.users.meta)
  const listCourse = useSelector((state) => state.courses.lists)

  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Authors
        title={"รายชื่อผู้ใช้งาน"}
        captions={["ชื่อ - นามสกุล", "ชั้นเรียน", "สถานะ", "ปุ่มพิมพ์",  "", "", ""]}
        data={lists}
        meta={meta}
        listCourse={listCourse}
      />
    </Flex>
  );
}

export default Tables;
