import { createSlice } from '@reduxjs/toolkit';
import api from './api';
import { ReducerQuestions } from './types';

export const GET_QUESTIONS = () => async (dispatch) => {
  const res = await api.getQuestions()
  dispatch({ type: ReducerQuestions.SET_QUESTIONS, data: res.data })
}

export const GET_QUESTION_BY_ID = (id) => async (dispatch) => {
  const res = await api.getQuestionById(id)
  dispatch({ type: ReducerQuestions.SET_QUESTION, data: res.data })
  return res.data
}

export const GET_QUESTION_BY_EXAM = (id) => async (dispatch) => {
  const res = await api.getQuestionByExam(id)
  dispatch({ type: ReducerQuestions.SET_QUESTION, data: res.data })
  return res.data
}

export const CREATE_QUESTION = (body) => async (dispatch) => {
  const res = await api.createQuestion(body)
  return res.data
}

export const UPDATE_QUESTION = (id, body) => async (dispatch) => {
  const res = await api.updateQuestion(id, body)
  return res.data
}

export const CREATE_QUESTIONS_JSON_EXCEL = (body) => async (dispatch) => {
  const res = await api.addQuestionJsonExcel(body)
  return res.data
}

export const DELETE_QUESTION = (id) => async (dispatch) => {
  const res = await api.deleteQuestion(id)
  return res.data
}

const questionsReducer = createSlice({
  name: 'questions',
  initialState: {
    lists: [],
    question: {}
  },
  extraReducers: {
    [ReducerQuestions.SET_QUESTIONS]: (state, action) => {
      state.lists = action.data
    },
    [ReducerQuestions.SET_QUESTION]: (state, action) => {
      state.question = action.data
    }
  },
})

const { reducer } = questionsReducer
export default reducer
