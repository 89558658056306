// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_EXAMS } from 'store/exams';
import Authors from "./components/Authors";

function Tables() {
  const dispatch = useDispatch()
  const initFetch = useCallback(() => {
    dispatch(GET_EXAMS(''))
  }, [dispatch])
  useEffect(() => {
    initFetch()
  }, [initFetch])
  const lists = useSelector((state) => state.exams.lists)
  return (
    <Flex direction='column' pt={{ base: "80px", md: "75px" }}>
      <Authors
        title={"รายชื่อชุดข้อสอบ"}
        captions={["ชื่อชุดข้อสอบ", "ชื่อไฟล์", "จำนวนข้อ", "พิมพ์ข้อสอบ", "", ""]}
        data={lists}
      />
    </Flex>
  );
}

export default Tables;
