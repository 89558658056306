// import
import { CalendarIcon, StarIcon } from '@chakra-ui/icons';
import { DocumentIcon, PersonIcon, RocketIcon, StatsIcon } from "components/Icons/Icons";
import Cookies from "js-cookie";
import Courses from "views/Admin/Courses";
import AddCourse from "views/Admin/Courses/AddCourse";
import EditCourse from 'views/Admin/Courses/EditCourse';
import Exams from "views/Admin/Exams";
import AddExam from "views/Admin/Exams/AddExam";
import EditExam from "views/Admin/Exams/EditExam";
import Question from "views/Admin/Question";
import AddQuestion from "views/Admin/Question/AddQuestion";
import EditQuestion from "views/Admin/Question/EditQuestion";
import Users from "views/Admin/Users";
import EditCourseUser from "views/Admin/Users/EditCourseUser";
import EditUser from "views/Admin/Users/EditUser";
import StatUser from "views/Admin/Users/StatUser";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import AllCourse from "views/User/AllCourse";
import CourseDetail from "views/User/CourseDetail";
import MyCourse from "views/User/MyCourse";
import MyFreeCourse from "views/User/MyFreeCourse";
import Profile from "views/User/Profile";
import TakeExam from "views/User/TakeExam";
import ResultExam from "views/User/TakeExam/ResultExam";
import WrongExam from "views/User/TakeExam/WrongExam";

const role = Cookies.get("role")

var dashRoutes = [
  {
    path: "/exams/add",
    name: "เพิ่มชุดข้อสอบ",
    icon: <DocumentIcon color="inherit" />,
    component: AddExam,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/exams/:id/edit",
    name: "แก้ไขชุดข้อสอบ",
    icon: <DocumentIcon color="inherit" />,
    component: EditExam,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/exams/:id/question/add",
    name: "เพิ่มคำถาม",
    icon: <DocumentIcon color="inherit" />,
    component: AddQuestion,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/exams/:id/question/:questionId/edit",
    name: "แก้ไขคำถาม",
    icon: <DocumentIcon color="inherit" />,
    component: EditQuestion,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/exams/:id/question/",
    name: "คำถาม",
    icon: <DocumentIcon color="inherit" />,
    component: Question,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/courses/add",
    name: "เพิ่มคอร์สเรียน",
    icon: <DocumentIcon color="inherit" />,
    component: AddCourse,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/courses/edit/:id",
    name: "แก้ไขคอร์สเรียน",
    icon: <DocumentIcon color="inherit" />,
    component: EditCourse,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/users/:id/edit",
    name: "แก้ไขผู้ใช้งาน",
    icon: <DocumentIcon color="inherit" />,
    component: EditUser,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/users/course/:id/edit",
    name: "แก้ไขคอร์สเรียนผู้ใช้งาน",
    icon: <DocumentIcon color="inherit" />,
    component: EditCourseUser,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/users/stat/:id/",
    name: "สถิติผู้ใช้งาน",
    icon: <DocumentIcon color="inherit" />,
    component: StatUser,
    layout: "/admin",
    display: "hide"
  },
  {
    path: "/users",
    name: "ผู้ใช้งาน",
    icon: <PersonIcon color="inherit" />,
    component: Users,
    layout: "/admin",
    display: role == 'Admin' ? "" : "hide"
  },
  {
    path: "/courses",
    name: "คอร์สเรียน",
    icon: <DocumentIcon color="inherit" />,
    component: Courses,
    layout: "/admin",
    display: role == 'Admin' ? "" : "hide"
  },

  {
    path: "/exams",
    name: "ชุดข้อสอบ",
    icon: <StatsIcon color="inherit" />,
    component: Exams,
    layout: "/admin",
    display: role == 'Admin' ? "" : "hide"
  },
  {
    path: "/profile",
    name: "ข้อมูลทั่วไป",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/user",
    display: role == 'User' ? "" : "hide"
  },
  {
    path: "/allcourse/coursedetail/:id",
    name: "รายละเอียดคอร์สเรียน",
    icon: <PersonIcon color="inherit" />,
    component: CourseDetail,
    layout: "/user",
    display: "hide"
  },
  {
    path: "/allcourse",
    name: "คอร์สเรียนทั้งหมด",
    icon: <DocumentIcon color="inherit" />,
    component: AllCourse,
    layout: "/user",
    display: role == 'User' ? "" : "hide"
  },
  {
    path: "/takeexam/:course_id/:id",
    name: "ทำข้อสอบ",
    icon: <PersonIcon color="inherit" />,
    component: TakeExam,
    layout: "/exam",
    display: "hide"
  },
  {
    path: "/resultexam",
    name: "ผลการทำข้อสอบ",
    icon: <PersonIcon color="inherit" />,
    component: ResultExam,
    layout: "/exam",
    display: "hide"
  },
  {
    path: "/wrongexam",
    name: "ข้อสอบที่ทำผิด",
    icon: <PersonIcon color="inherit" />,
    component: WrongExam,
    layout: "/exam",
    display: "hide"
  },
  {
    path: "/mycourse/coursedetail/:id",
    name: "รายละเอียดคอร์สเรียน",
    icon: <PersonIcon color="inherit" />,
    component: CourseDetail,
    layout: "/user",
    display: "hide"
  },
  {
    path: "/myfreecourse/coursedetail/:id",
    name: "รายละเอียดคอร์สเรียน",
    icon: <PersonIcon color="inherit" />,
    component: CourseDetail,
    layout: "/user",
    display: "hide"
  },
  {
    path: "/mycourse",
    name: "คอร์สเรียนของฉัน",
    icon: <CalendarIcon color="inherit" />,
    component: MyCourse,
    layout: "/user",
    display: role == 'User' ? "" : "hide"
  },
  {
    path: "/myfreecourse",
    name: "คอร์สเรียนฟรี",
    icon: <StarIcon color="inherit" />,
    component: MyFreeCourse,
    layout: "/user",
    display: role == 'User' ? "" : "hide"
  },
  {
    path: "/login",
    name: "Sign In",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    display: "hide"
  },
  {
    path: "/signup",
    name: "Sign Up",
    icon: <RocketIcon color="inherit" />,
    component: SignUp,
    layout: "/auth",
    display: "hide"
  }
];
export default dashRoutes;
