// Chakra imports
import {
  Grid, useColorModeValue
} from "@chakra-ui/react";
import eng from "assets/img/eng.jpg";
import math from "assets/img/math.jpg";
import sci from "assets/img/sci.jpg";
import social from "assets/img/social.jpg";
import thai from "assets/img/thai.jpg";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_COURSES } from "store/courses";
import ExamCard from "./ExamCard";

const Exams = ({ title, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const dispatch = useDispatch()
  const lists = useSelector((state) => state.courses.lists)

  const imageSubject = (subject) => {
    if (subject == 'คณิตศาสตร์') {
      return math
    }  else if (subject == 'วิทยาศาสตร์') {
      return sci
    } else if (subject == 'สังคมศึกษา') {
      return social
    } else if (subject == 'ภาษาอังกฤษ') {
      return eng
    } else if (subject == 'ภาษาไทย') {
      return thai
    }
  }

  const fetchData = useCallback((searchInput) => {
    dispatch(GET_COURSES(`?search=${searchInput}`))
  }, [dispatch])

  const courseFree = (item) => {
    if (item.is_free) {
      return true;
    } else {
      return false;
    }
  }

  const coursePaid = (item) => {
    if (!item.is_free) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Card p='16px' my='24px'>
      <CardHeader mb='1rem'>
        <SearchBar fetchData={fetchData} />
      </CardHeader>
      <CardBody px='5px'>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
          templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
          gap='24px'>
          {lists.map((item)=> {
            if(item.status) {
              return (
                <ExamCard
                  key={item.id}
                  image={imageSubject(item.subject)}
                  name={item.name}
                  category={`${item.subject} ${item.class}`}
                  description={item.description}
                  id={item.id}
                />
              )
            }
          })}
        </Grid>
      </CardBody>
    </Card>
  );

  // return (
  //   <Card p='16px' my='24px'>
  //     <CardHeader mb='1rem'>
  //       <SearchBar fetchData={fetchData} />
  //     </CardHeader>
  //     <Tabs variant='soft-rounded' colorScheme='green'>
  //       <TabList>
  //         <Tab>คอร์สเรียนทั้งหมด</Tab>
  //         <Tab>คอร์สเสียเงิน</Tab>
  //         <Tab>คอร์สฟรี</Tab>
  //       </TabList>
  //       <TabPanels>
  //         <TabPanel>
  //           <CardBody px='5px'>
  //             <Grid
  //               templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
  //               templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
  //               gap='24px'>
  //               {lists.map((item)=> {
  //                 if(item.status) {
  //                   return (
  //                     <ExamCard
  //                       key={item.id}
  //                       image={imageSubject(item.subject)}
  //                       name={item.name}
  //                       category={`${item.subject} ${item.class}`}
  //                       description={item.description}
  //                       id={item.id}
  //                     />
  //                   )
  //                 }
  //               })}
  //             </Grid>
  //           </CardBody>
  //         </TabPanel>
  //         <TabPanel>
  //           <CardBody px='5px'>
  //             <Grid
  //               templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
  //               templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
  //               gap='24px'>
  //               {lists.filter(coursePaid).map((item)=> {
  //                 if(item.status) {
  //                   return (
  //                     <ExamCard
  //                       key={item.id}
  //                       image={imageSubject(item.subject)}
  //                       name={item.name}
  //                       category={`${item.subject} ${item.class}`}
  //                       description={item.description}
  //                       id={item.id}
  //                     />
  //                   )
  //                 }
  //               })}
  //             </Grid>
  //           </CardBody>
  //         </TabPanel>
  //         <TabPanel>
  //           <CardBody px='5px'>
  //             <Grid
  //               templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
  //               templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
  //               gap='24px'>
  //               {lists.filter(courseFree).map((item)=> {
  //                 if(item.status) {
  //                   return (
  //                     <ExamCard
  //                       key={item.id}
  //                       image={imageSubject(item.subject)}
  //                       name={item.name}
  //                       category={`${item.subject} ${item.class}`}
  //                       description={item.description}
  //                       id={item.id}
  //                     />
  //                   )
  //                 }
  //               })}
  //             </Grid>
  //           </CardBody>
  //         </TabPanel>
  //       </TabPanels>
  //     </Tabs>
  //   </Card>
  // );

};

export default Exams;
