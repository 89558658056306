// Chakra imports
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

const ProjectCard = ({ image, name, category, id, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  const routeDetail = () =>{ 
    let path = `/user/myfreecourse/coursedetail/${id}`; 
    history.push(path);
  }

  return (
    <Flex direction='column'>
      <Box mb='20px' position='relative' borderRadius='15px'>
        <Image src={image} borderRadius='15px' />
        <Box
          w='100%'
          h='100%'
          position='absolute'
          top='0'
          borderRadius='15px'></Box>
      </Box>
      <Flex direction='column'>
        <Text fontSize='md' color='gray.500' fontWeight='600' mb='10px'>
          {category}
        </Text>
        <Text fontSize='xl' color={textColor} fontWeight='bold' mb='10px'>
          {name}
        </Text>
        <Text fontSize='md' color='gray.500' fontWeight='400' mb='20px' noOfLines={3}>
          {description}
        </Text>
        <Flex justifyContent='space-between'>
          <Button
            variant='outline'
            colorscheme='teal'
            minW='110px'
            h='36px'
            fontSize='xs'
            px='1.5rem'
            onClick={routeDetail}>
            เข้าทำข้อสอบ
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectCard;
